import React from 'react'
import styled from 'styled-components'
import SectionHeader from '../../components/SectionHeader'
import ReactContactForm from 'react-mail-form'

const StyledContactContainer = styled.section`
  width: 100%;
  max-width: 992px;
  padding: 0 35px;
  margin: 100px auto;
`

const StyledForm = styled(ReactContactForm)`
  width: 100%;
  display: flex;
  flex-flow: column;

    input {
      background: transparent;
      border: none;
      border-bottom: 1px solid #fff;
      margin-bottom: 25px;
      font-size: 18px;
      color: #fff;
      padding: 10px;
    }

    textarea {
      background: transparent;
      border: 1px solid #fff;
      resize: none;
      height: 300px;
      margin-bottom: 25px;
      color: #fff;
      padding: 10px;
    }

    a {
      display: inline-block;
      width: 190px;
      border: 2px solid #fff;
      padding: 18px 0px;
      color: #fff;
      text-transform: uppercase;
      font-size: 14px;
      border-radius: 6px;
    }
`

const Contact = () => (
  <StyledContactContainer id="contact">
    <SectionHeader section="Contact" />

    <StyledForm 
      to={'info@globalinheritance.org'} 
      className="contact-form" 
      titlePlaceholder="Subject"
      contentsPlaceholder="Message"
      buttonText="Send"
    />
  
  </StyledContactContainer>
)

export default Contact