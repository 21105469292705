import React, { useState } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { Link } from 'react-router-dom'
import get from 'lodash/get'
import Mailchimp from '../../components/Mailchimp'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import GalleryModal from '../../components/GalleryModal'
import StudentsImage1 from '../../earthday-logo.png'
import GALLERY_DATA from './examples.json'
import ExpandButton from '../../expand.svg'
import DownloadButton from '../../download.svg'
import BackgroundImage from '../../designcontest-bg.svg'
import Goldenvoice from '../../goldenvoice.png'
import Montys from '../../montys.png'
import Juneshine from '../../juneshine.png'
import Propeller from '../../propeller.png'
import Example from '../../design-1-alt.jpeg'
import GiLogo from '../../gi-logo.png'

const DesignContestGlobal = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Glegoo:wght@400;700&display=swap');

  body {
    background-image: url(${BackgroundImage}), linear-gradient(180deg, #1C4F6B 0%, #127595 50%);
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: bottom;
  }
`;

const StyledContainer = styled.section`
  width: 100%;
  max-width: 992px;
  padding: 0 35px;
  margin: 25px auto;
`

const StyledInner = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
`

const NavLinks = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin: 25px 0 45px;
`

const NavLink = styled.div`
  margin: 0 15px;  
  a {
    color: #000;
    text-decoration: none;
    display: block;
    border: 3px solid #fff;
    padding: 10px 15px;
    background: #fff;
    transition: all 0.3s ease;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 18px;
    box-shadow: 4px 4px 0 #000;
  }

  &:hover {
    a {
      color: #fff;
      background: transparent;
      text-decoration: none;
    }
  }
`

const StyledText = styled.div`
  font-size: 18px;
  margin-bottom: 25px;
  color: #fff;
  line-height: 1.4;
  text-align: left;
  width: 100%;

  img {
    float: right;
    width: 100%;
    max-width: 350px;
    padding: 15px;
  }

  p {
    margin-bottom: 25px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  a {
    color: #fff;
    text-decoration: underline;
    font-weight: bold;
  }

  ${({nospace}) => nospace && `
    p {
      margin-bottom: 0;
      text-align: left;
    }
  `}

  ${({box}) => box && `
    background-color: #d0f0fd;
    box-shadow: 4px 4px 0 #000;
    padding: 30px;
    color: #000;
    width: auto;
    margin: 15px auto 35px;
  `}
  ${({centered}) => centered && `
    text-align: center;
  `}

  ${({half}) => half && `
    @media(min-width: 768px) {
      width: calc(50% - 25px);
    }
  `}

  ul {
    text-align: center;

    li {
      margin: 0 0 35px 0;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-flow: column;

      &:last-of-type {
        margin-bottom: 0;
      }

      @media (min-width: 768px) {
        flex-flow: row;
      }

      span {
        font-size: 24px;
        font-weight: 700;
        font-family: 'Glegoo', serif;
        padding: 0 10px;
        border-bottom: 3px solid #000;
        margin-right: 10px;
        margin-bottom: 10px;
        width: 70px;

        @media (min-width: 768px) {
          border-bottom: none;
          border-right: 3px solid #000;
          margin-bottom: 0;
        }
      }

      div {
        display: flex;
        flex-flow: column;
        align-items: flex-start;
      }
    }
  }

  .disc {
    list-style-type: disc;
  }
`

const StyledTextTitle = styled.h1`
  font-size: 32px;
  font-family: 'Glegoo', serif;
  font-weight: 700;
  font-style: bold;
  margin: 25px 0;
  color: #fff;
  text-align: center;
  width: 100%;

  ${({dark}) => dark && `
    color: #000;
    margin-top: 0;
  `}
`

const StyledStudentsImage = styled.img`
  width: 80%;
`

const StyledListContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  width: 100%;
`

const StyledListItem = styled.li`
  width: calc(100% - 20px);
  margin-bottom: 120px;
  position: relative;
  list-style-type: none;

  img {
    width: 100%;
  }

  @media(min-width: 768px) {
    width: calc(50% - 20px);
  }
`

const StyledRolloverContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 80px;
  background: rgba(0,0,0,0.5);
  top: 100%;
  left: 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease;

  @media(min-width: 769px) {
    height: 95.45%;
    top: 16px
    opacity: 0;
  }

  &:hover {
    opacity: 1;
  }
`

const StyledGradient = styled.div`
  position: relative;
  color: #000;
  text-align: center;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(45deg, #ffd800, #ff5520, #750cf2, #0cbcf2);
    z-index: -2;
    filter: blur(40px);
    opacity: 0.1;
  }
`

const StyledRolloverButton = styled.div`
  width: 35px;
  height: 35px;
  margin: 20px;
  cursor: pointer;

  @media(min-width: 769px) {
    width: 50px;
    height: 50px;
  }

  img {
    width: 100%;
  }
`;

const StyledHr = styled.hr`
  background: #fff;
  width: 200px;
`

const StyledPartner = styled.div`
  width: calc(100% - 20px);
  margin-bottom: 120px;
  position: relative;
  box-shadow: 4px 4px 0 #000;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 50%;
    padding: 20px 0;

    ${({ larger }) => larger && `
      width: 75%;
    `}
  }

  @media(min-width: 768px) {
    width: calc(25% - 20px);
  }
`

const StyledFooter = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 18px;
  margin: 15px;
  font-weight: bold;

  p {
    margin: 5px 0;
  }

  a {
    color: #fff;
    text-decoration: underline;
  }

  img {
    width: 175px;
    margin: -2px 5px 0 5px;
  }
`

const DesignContest = () => {
  const [showGalleryModal, setShowGalleryModal] = useState(false)
  const [activeImageUrl, setActiveImageUrl] = useState('')
  const galleryData = get(GALLERY_DATA, 'data', [])
  const activeGalleryPosters = galleryData.find(x => x.name === 'Examples').posters
  // document.body.style.backgroundColor = 'black';

  return (
    <StyledContainer id="poster-submission">
      <DesignContestGlobal />
      <StyledStudentsImage src={StudentsImage1} />
      <StyledTextTitle>Poster Design Contest</StyledTextTitle>
      <StyledText centered>Presented by Global Inheritance, Goldenvoice, Monty's Good Burger, Propeller and&nbsp;JuneShine</StyledText>
      <StyledHr />
      <NavLinks>
        <NavLink><AnchorLink href="#submit" offset='60'>Submit Your Concept</AnchorLink></NavLink>
      </NavLinks>
      <StyledInner>
        <StyledText>
          Can You Predict the Future of Earth Day?
        </StyledText>
        <StyledText>
          <p>
          Set your imagination for 100 years in the future, and describe what you see using your creativity. <strong>Create a shareable poster concept capturing your predictions about climate change using words, pictures, drawings, or digital tools</strong>, and you can win prizes like tickets to upcoming <strong>Goldenvoice shows</strong>, <strong>Monty’s Good Burger Gift Card</strong> and even <strong>JuneShine for your whole office</strong>. Select concepts will be re-designed by professional designers.
          </p>
          <img src={Example} alt="Design Contest Example" /> 

           <p>
           Will climate change get better or worse in 100 years? Are you counting on techno-futurism to geoengineer a utopian tomorrow, or will status quo bias lead to a dystopian catastrophe? Do you feel optimistic or pessimistic about our chances of establishing an equitable and sustainable world?
           </p>
           <p>
           The Earth Day 2121 collection of POSTed Editions will be a representation of a future that is ours to create - but who will lead the way?  By crowdsourcing creative concepts that visualize our speculative future, we can reveal insights about our odds. For example, if we imagine 100 concepts are submitted, and those concepts were weighted 60%-40% in favor of optimism - we will curate the most compelling concepts matching that ratio - 6 optimistic and 4 pessimistic.
           </p>
           <p>
           <AnchorLink href="#submit" offset='60'>Upload</AnchorLink> the posters concepts you’ve come up with by <strong>April 30th by 5pm PST</strong>. You can submit as many ideas as you want to share with the world, but all poster concepts must be received prior to the deadline for consideration. 
           </p>
        </StyledText>
        <StyledText box nospace gradient>
          <StyledGradient>
          <StyledTextTitle dark>Grand Prizes include:</StyledTextTitle>
            <ul>
              <li>
                <span>1st</span>
                <div>
                  <p>• Choice of a pair of tickets to 10 Goldenvoice shows*</p>
                  <p>• $100 Monty’s Good Burger Gift Card</p>
                </div>
              </li>
              <li>
                <span>2nd</span>
                <div>
                  <p>• Choice of a pair of tickets to 5 Goldenvoice shows*</p>
                  <p>• $50 Monty’s Good Burger Gift Card</p>
                </div>
              </li>
              <li>
                <span>3rd</span>
                <div>
                  <p>• Choice of a pair of tickets to 2 Goldenvoice shows*</p>
                  <p>• $25 Monty’s Good Burger Gift Card</p>
                </div>
              </li>
            </ul>
          </StyledGradient>
        </StyledText>
        
        <StyledText>For design and creative agencies, the best poster concept will receive a six month supply of JuneShine for&nbsp;their&nbsp;office!</StyledText>
        <StyledText>Prize winners and the collection of redesigned posters will be shared on <strong>May 25, 2021</strong>.</StyledText>
        {/* <StyledText>
            have been called upon in times of turmoil to deploy their visual communication talent on behalf of the greater good. The POSTed Studio is a program designed to amplify and gain visibility for important ideas by harnessing collective creativity as a source of inspiration and persuasion. The most creative poster concepts are then turned into high-quality poster prints designed by professional artists and shared at no charge with the world via our online poster library to use at marches, rallies, school presentations or other non-commercial purposes. This Earth Month we are focused on bringing as many participants and partners into the conversation to play a part in creating a historic display of climate action.
        </StyledText> */}
        <StyledTextTitle id="examples">Examples</StyledTextTitle>
        <StyledListContainer>
          {activeGalleryPosters.map(gallery => (
            <StyledListItem key={gallery.thumbUrl}>
              <img src={gallery.thumbUrl} />
              <StyledRolloverContainer>
                <StyledRolloverButton onClick={() => {
                  setActiveImageUrl(gallery.thumbUrl)
                  setShowGalleryModal(true)
                }}>
                  <img src={ExpandButton} />
                </StyledRolloverButton>
                <StyledRolloverButton>
                  <a href={gallery.posterUrl} target='_blank'>
                    <img src={DownloadButton} />
                  </a>
                </StyledRolloverButton>
              </StyledRolloverContainer>
            </StyledListItem>
          ))}
        </StyledListContainer>
        {showGalleryModal && (
          <GalleryModal 
            toggle={() => setShowGalleryModal(!showGalleryModal)}
            imgUrl={activeImageUrl}
          />
        )}
        <StyledText>
          <StyledTextTitle id="submit">Submit your concept</StyledTextTitle>
          <iframe className="airtable-embed" src="https://airtable.com/embed/shrA7ezcYnljouWJI?backgroundColor=gray" frameBorder="0"  width="100%" height="533" style={{'background': '#fff', 'border': 'none', 'box-shadow': '4px 4px 0 #000'}}></iframe>
        </StyledText>

        <StyledTextTitle id="partners">Partners</StyledTextTitle>
        <StyledListContainer>
          <StyledPartner larger>
          <a href="https://www.goldenvoice.com" target="_blank">
          <img src={Goldenvoice} />

          </a>
          </StyledPartner>
          <StyledPartner>
          <a href="https://montysgoodburger.com" target="_blank">            <img src={Montys} />
</a>
          </StyledPartner>
          <StyledPartner>
          <a href="https://juneshine.com" target="_blank"><img src={Juneshine} />
</a>
          </StyledPartner>
          <StyledPartner larger>
            <a href="https://www.propeller.la" target="_blank"><img src={Propeller} /></a>
            
          </StyledPartner>
        </StyledListContainer>
        
        <Mailchimp
          tag='sign-up'
          title='Sign Up for our Newsletter'
          messages={{
            button: 'Submit'
          }}
          action='https://globalinheritance.us2.list-manage.com/subscribe/post?u=40e700f186279051f3a238f8a&amp;id=ac44d8926b'
          fields={[
            {
              name: 'EMAIL',
              placeholder: 'Email',
              type: 'email',
              required: true,
              width: '100%',
            },
            {
              name: 'FNAME',
              placeholder: 'First Name',
              type: 'text',
              required: true,
              width: 'calc(50% - 10px)',
            },
            {
              name: 'LNAME',
              placeholder: 'Last Name',
              type: 'text',
              required: true,
              width: 'calc(50% - 10px)'
            },
            {
              name: 'group[268][1]',
              placeholder: 'Artist?',
              type: 'checkbox',
              label: `I'm an artist interested in creating a redesign`
            }
          ]}
        /> 

        <StyledFooter>
          POSTed Studio is a <a href="https://www.globalinheritance.org" target="_blank"><img src={GiLogo} alt="Global Inheritance" /></a> initiative. 
          <p>*<i>Winner will be eligible to select up to X pairs of tickets to a selection of upcoming Goldenvoice events.</i></p>
          <p>**<i>By checking this box and submitting my Concept Submission, I acknowledge that I am granting Global Inheritance an irrevocable and royalty free license to copy, distribute, and display my Concept Submission and to use my Concept Submission to create other works or merchandise.</i></p>
          <p>Full contest rules can be found <Link to="/rules">here</Link>.</p>
        </StyledFooter>
      </StyledInner>

    </StyledContainer>
  )
}

export default DesignContest