import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'

const StyledImageContainer = styled.div`
  position: relative;
`

const StyledArtistBio = styled.div`
  position: relative;
`

const ArtistModal = ({
 toggle, artistName, artistImage, artistBio,
}) => {
  return (
    <Modal 
      isOpen
      toggle={toggle}
    >
      <ModalHeader toggle={toggle}>{artistName}</ModalHeader>
      <ModalBody>
        <StyledImageContainer>
          <img src={artistImage} />
        </StyledImageContainer>
        <StyledArtistBio>
          {artistBio}
        </StyledArtistBio>
      </ModalBody>
    </Modal>
  );
};

ArtistModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  artistName: PropTypes.string.isRequired,
  artistImage: PropTypes.string.isRequired,
  artistBio: PropTypes.string.isRequired,
};

export default ArtistModal;
