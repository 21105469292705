import React, { useState } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import get from 'lodash/get'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import GalleryModal from '../../components/GalleryModal'
import StudentsImage1 from '../../posted-office-header.png'
import Posted1Pdf from '../../posted-office-1.pdf'
import Posted2Pdf from '../../posted-office-2.pdf'
import Posted3Pdf from '../../posted-office-3.pdf'
import Posted4Pdf from '../../posted-office-4.pdf'
import Posted5Pdf from '../../posted-office-5.pdf'
import Posted1 from '../../posted-office-1.png'
import Posted2 from '../../posted-office-2.png'
import Posted3 from '../../posted-office-3.png'
import Posted4 from '../../posted-office-4.png'
import Posted5 from '../../posted-office-5.png'
import PostedBuilding from '../../POSTedOFFICE_Building.png';
import ExpandButton from '../../expand.svg'
import DownloadButton from '../../download.svg'

const DesignContestGlobal = createGlobalStyle`
  body {
    background: #2fa8e0;
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; 
  }
`;

const StyledContainer = styled.section`
  max-width: 1024px;
  padding: 0 35px;
  margin: 25px auto;
  width: 100%;
`

const StyledInner = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
`

const StyledStudentsImage = styled.img`
  width: 100%;
  max-width: 600px;
  display: block;
`

const StyledListContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  width: 100%;
  list-style-type: none;

`

const StyledListItem = styled.li`
  width: calc(100% - 20px);
  margin-bottom: 32px;
  position: relative;
  list-style-type: none;

  h1 {
    color: white;
    font-size: 48px;
    font-weight: 700;
    letter-spacing: -1.5px;
    text-align: left;  
    margin-bottom: 8px;  
  }

  h3 {
    color: white;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: -1.5px;
    text-align: left;
    margin-top: 58px;
    margin-bottom: 12px;


  }

  p {
    text-align: left;
    color: white;
    font-weight: 500;
    font-size: 18px;
    margin-top: 16px;

    a {
      color: white;
      text-decoration: none;
    }
  }

  img {
    width: 100%;
    border: 1px solid white;
  }

  @media(min-width: 768px) {
    width: calc(50% - 20px);

    h3 {
      margin-top: 8;
    }
  }
` 

const StyledIntroText = styled.div`
 margin-top: 32px;
 margin-bottom: 48px;
 border-top: 2px solid white;
 border-bottom: 2px solid white;
 padding: 32px 0;
 display: flex;
 flex-flow: column;
 justify-content: space-between;

 @media(min-width: 769px) {
    flex-flow: row;
  }

 .text-container {
  display: flex;
  flex-flow: column;
  width: 100%;

  @media(min-width: 769px) {
    width: 65%;
  }

  h1 {
    color: white;
    font-size: 48px;
    font-weight: 700;
    letter-spacing: -1.5px;
    text-align: left;
    line-height: 48px;
  }

  p {
    text-align: left;
    color: white;
    font-weight: 500;
    font-size: 18px;
    margin-top: 16px;
  }
 }

 .img-container {
    position: relative;
    width: 100%;
    display: block;

    @media(min-width: 769px) {
      width: 60%;
      margin-right: -125px;
      margin-top: -60px;
    }

    @media(min-width: 1024px) {
      width: 30%;
      margin-right: 10px;
    }
       
    img {
      width: 100%;
    }
  }
`;

const StyledOutroContainer = styled.div`
  margin-top: 8px;
  border-top: 2px solid white;
  padding-top: 32px;
  display: flex;
  flex-flow: column;

  p {
    text-align: left;
    color: white;
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 16px;
  }

  a {
    padding: 12px 16px
    background: white;
    color: #2fa8e0;
    font-weight: bold;
    border: none;
    margin: 8px 0 32px;
    display: inline-block;
    align-self: flex-start;
    transition: background 0.3s ease-in-out;

    &:hover {
      text-decoration: none;
      background: #f8f8f8;
    }
  }
`

const StyledRolloverContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 50px;
  background: rgba(0,0,0,0.15);
  top: 100%;
  left: 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease;

  @media(min-width: 769px) {
    height: 100%
    top: 0
    opacity: 0
  }

  &:hover {
    opacity: 1;
  }
`

const StyledRolloverButton = styled.div`
  width: 35px;
  height: 35px;
  margin: 5px 15px;
  cursor: pointer;

  @media(min-width: 769px) {
    width: 50px;
    height: 50px;
  }

  img {
    width: 100%;
    border: none;
  }
`

const StyledImageContainer = styled.div`
  position: relative;
`

const StudentsForJustice = () => {
  const [showGalleryModal, setShowGalleryModal] = useState(false)
  const [activeImageUrl, setActiveImageUrl] = useState('')

  return (
    <StyledContainer id="posted-office">
      <DesignContestGlobal />
      <StyledStudentsImage src={StudentsImage1} />
      <StyledIntroText>
         <div className="text-container">
          <h1>
              Camp Flog Gnaw, 10th&nbsp;Year<br/>
              Los Angeles, CA
            </h1>
            <p>
            The POSTed Office provides a platform for people to share their thoughts and beliefs, at specific places and moments in time, in a tangible and lasting way.
            </p>
         </div>
         <div className="img-container">
          <img src={PostedBuilding} />
         </div>
      </StyledIntroText>
      {/* <NavLinks>
        <NavLink><AnchorLink href="#submit" offset='60'>Submit</AnchorLink></NavLink>
        <NavLink><AnchorLink href="#sign-up" offset='60'>Sign Up</AnchorLink></NavLink>
      </NavLinks> */}
      <StyledInner>
      <StyledListContainer>
        <StyledListItem>
          <h1>Little Tokyo</h1>
          <StyledImageContainer>
            <img src={Posted1} alt="Camp Flog Gnaw POSTed Office 1" />
            <StyledRolloverContainer>
              <StyledRolloverButton onClick={() => {
                setActiveImageUrl(Posted1)
                setShowGalleryModal(true)
              }}>
                <img src={ExpandButton} />
              </StyledRolloverButton>
              <StyledRolloverButton>
                <a href="https://drive.google.com/file/d/1YYK7nujfL4zrNYS4maCaNiq5P4ARgDSn/view?usp=drive_link" target='_blank'>
                  <img src={DownloadButton} />
                </a>
              </StyledRolloverButton>
            </StyledRolloverContainer>
          </StyledImageContainer>
          <h3>Paulo Pastel</h3>
          <p>Paulo Pastel is a Filipino-American artist from Los Angeles, CA. His work focuses on color, form, and perception. Inspired by the blending of nature, nostalgia, pop culture, and abstraction, he creates work that merges and plays with the in betweenness of representational and abstract.</p>
          <p><a href="https://www.paulopastel.com" target="_blank" rel="noreferrer noopener">www.paulopastel.com</a></p>
        </StyledListItem>
        <StyledListItem>
            <h1>Glassell Park</h1>
            <StyledImageContainer>
              <img src={Posted2} alt="Camp Flog Gnaw POSTed Office 2" />
            <StyledRolloverContainer>
              <StyledRolloverButton onClick={() => {
                setActiveImageUrl(Posted2)
                setShowGalleryModal(true)
              }}>
                <img src={ExpandButton} />
              </StyledRolloverButton>
              <StyledRolloverButton>
                <a href="https://drive.google.com/file/d/1HnGSKVbEMnK5lqRNz_QrrNURXsdhqR2z/view?usp=drive_link" target='_blank'>
                  <img src={DownloadButton} />
                </a>
              </StyledRolloverButton>
            </StyledRolloverContainer>
          </StyledImageContainer>
            <h3>Shai Kim</h3>
            <p>Shai Kim, based in Los Angeles, CA, is influenced by pop culture and animation, especially anime and manga. Growing up, he was an avid reader of sci-fi and fantasy, watched a ton of movies, and played way too many video games - all of which had a significant impact on his creative interests and fueled his desire to contribute to the visual landscape.</p>
           <p><a href="https://www.everybody-bento.com" target="_blank" rel="noreferrer noopener">www.everybody-bento.com</a></p>
        </StyledListItem>
        <StyledListItem>
            <h1>Culver City</h1>
            <StyledImageContainer>
              <img src={Posted3} alt="Camp Flog Gnaw POSTed Office 3" />
              <StyledRolloverContainer>
                <StyledRolloverButton onClick={() => {
                  setActiveImageUrl(Posted3)
                  setShowGalleryModal(true)
                }}>
                  <img src={ExpandButton} />
                </StyledRolloverButton>
                <StyledRolloverButton>
                  <a href="https://drive.google.com/file/d/1jvRuwW3fEXDNwxqThy0pg5WZ0QcHcnRz/view?usp=drive_link" target='_blank'>
                    <img src={DownloadButton} />
                  </a>
                </StyledRolloverButton>
              </StyledRolloverContainer>
            </StyledImageContainer>
            <h3>Alex Cerutti</h3>
            <p>Alex Cerutti is a multidisciplinary artist and designer from Los Angeles, CA. He's behind Change The Game, his art/design practice focused on creating a colorful world of abstract, shapes, graphics, and patterns. His creative ethos centers around challenging yourself, pushing beyond your first thoughts, taking command of your own destiny, and doing so in a self-expressive way.</p>
           <p><a href="https://www.changethegame.studio" target="_blank" rel="noreferrer noopener">www.changethegame.studio</a></p>
        </StyledListItem>
        <StyledListItem>
            <h1>Pico Union</h1>
            <StyledImageContainer>
              <img src={Posted4} alt="Camp Flog Gnaw POSTed Office 4" />
              <StyledRolloverContainer>
                <StyledRolloverButton onClick={() => {
                  setActiveImageUrl(Posted4)
                  setShowGalleryModal(true)
                }}>
                  <img src={ExpandButton} />
                </StyledRolloverButton>
                <StyledRolloverButton>
                  <a href="https://drive.google.com/file/d/1H_mZyOCvAT3G8jELpjpjGN8BxYq4rDPs/view?usp=drive_link" target='_blank'>
                    <img src={DownloadButton} />
                  </a>
                </StyledRolloverButton>
              </StyledRolloverContainer>
            </StyledImageContainer>
          <h3>Jackie Hernandez</h3>
            <p>Jackie Hernandez, also known as Las Chicas Peligrosas, is a multi-medium artist born and raised in Los Angeles, CA - specifically from Pico-Union.</p>
            <p>They graduated from California State University, Long Beach with their B.A. in Studio Art and work with various mediums of art such as printmaking, illustration and murals.</p>
           <p><a href="https://www.laschicaspeligrosas.com" target="_blank" rel="noreferrer noopener">www.laschicaspeligrosas.com</a></p>
        </StyledListItem>
        <StyledListItem>
            <h1>Studio City</h1>
            <StyledImageContainer>
              <img src={Posted5} alt="Camp Flog Gnaw POSTed Office 5" />
              <StyledRolloverContainer>
                <StyledRolloverButton onClick={() => {
                  setActiveImageUrl(Posted5)
                  setShowGalleryModal(true)
                }}>
                  <img src={ExpandButton} />
                </StyledRolloverButton>
                <StyledRolloverButton>
                  <a href="https://drive.google.com/file/d/1MM7PDWmkgugaG9jB3BSxksboGmYi_rtl/view?usp=drive_link" target='_blank'>
                    <img src={DownloadButton} />
                  </a>
                </StyledRolloverButton>
              </StyledRolloverContainer>
            </StyledImageContainer>
          <h3>Ashley Dreyfus</h3>
            <p>Ashley Drevfus is an illustrator and mural artist based in Los Angeles
CA. Her art and characters exist in an alternate reality, where humans are transformed into their alter ego's, trees have grown legs, and cats drink coffee.</p>
            <p>A few of Ashley's influences include Keith Haring, vintage clothing/cartoons and her love of coffee.</p>
           <p><a href="https://www.dreyfusart.com" target="_blank" rel="noreferrer noopener">www.dreyusart.com</a></p>
        </StyledListItem>
      </StyledListContainer>
      <StyledOutroContainer>
      <p>Our digital world is experienced through overflowing inboxes, and endless scrolling feeds - an often anonymous stream of content that is glanced at, and mostly forgotten. This program renews the culturally diminished art of crafting handwritten, personal messages on the back of visually compelling postcards, and sharing them with others through the mail.</p>
      <p>The inspiring postcard designs available at the POSTed Office have been commissioned specifically for the event where they are featured. The artwork carries the themes, stories, and experiences of the artists and writers to points all across the country - deepening our personal connections and our ties to the community.</p>
      <p>Artwork that celebrates the environmental achievements of a city, or centers historic moments, or shines attention on the hard work being done to create a better future is the foundation of this program. Amplifying the connections between people who can participate in realizing that future are its purpose.</p>
      <p>To get involved, support, or collaborate in an upcoming POSTed Office, send us a message today.</p>
      <a href="mailto:info@globalinheritance.org">Get in Touch</a>
      </StyledOutroContainer>
      
      {showGalleryModal && (
        <GalleryModal 
          toggle={() => setShowGalleryModal(!showGalleryModal)}
          imgUrl={activeImageUrl}
        />
      )}

        {/* <Mailchimp
          tag='sign-up'
          title='Sign Up'
          messages={{
            button: 'Submit'
          }}
          action='https://globalinheritance.us2.list-manage.com/subscribe/post?u=40e700f186279051f3a238f8a&amp;id=ac44d8926b'
          fields={[
            {
              name: 'EMAIL',
              placeholder: 'Email',
              type: 'email',
              required: true,
              width: '100%',
            },
            {
              name: 'FNAME',
              placeholder: 'First Name',
              type: 'text',
              required: true,
              width: 'calc(50% - 10px)',
            },
            {
              name: 'LNAME',
              placeholder: 'Last Name',
              type: 'text',
              required: true,
              width: 'calc(50% - 10px)'
            },
            {
              name: 'group[268][1]',
              placeholder: 'Artist?',
              type: 'checkbox',
              label: `I'm an artist interested in creating a redesign`
            }
          ]}
        /> 
         */}

        {/* <StyledText>
          <StyledTextTitle>POSTed Studio Terms and Conditions</StyledTextTitle>
          <i>The Work of Art may be derived in part from a preexisting-work, the copyright in which preexisting work will be owned by or exclusively licensed to Global Inheritance. Artist understands that it is creating the Works of Art for the benefit of Global Inheritance. For good and valuable consideration, the receipt of which Artist acknowledges, Artist assigns to Global Inheritance any and all of its right, title, and interest in and to the Works of Arts, including the undivided copyrights and all other rights of any kind therein throughout the world, whether now known or hereafter recognized or created.</i>
        </StyledText> */}
      </StyledInner>

    </StyledContainer>
  )
}

export default StudentsForJustice