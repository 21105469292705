import React from 'react'
import styled from 'styled-components'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import PosterImage from '../../images/NG_TheTerritory_KeyArt_June21.jpg'
import Image1 from '../../images/TheTerritory_27.jpg'
import Image2 from '../../images/TheTerritory_Fire.jpeg'
import Image3 from '../../images/TheTerritory_6.jpg'
import Image4 from '../../images/Territory_Variety_1x1_A.jpg'
import StudentImage from '../../rsvp-submit-students.jpg'

const StyledContainer = styled.section`
  width: 100%;
  max-width: 1370px;
  padding: 25 35px;
  margin: 25px auto;
`

const StyledPageTitle = styled.h1`
  margin-bottom: 10px;
  font-size: 3.2em;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  line-height: 1;

  @media(min-width: 768px) {
    margin-bottom: 0;
    font-size: 3.8em;
  }
`

const StyledPageSubTitle = styled.h2`
  margin-bottom: 25px;
  font-size: 2.4em;
  text-align: center;
  color: #fff;
  font-family: celestia-antiqua-mvb, serif;
`

const StyledRule = styled.hr`
  background: #fff;
  width: 400px;
`

const StyledInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin: 0 auto 25px auto;
  flex-direction: column-reverse;
  padding: 0 55px;

  @media(min-width: 768px) {
    margin: 0 0 25px;
    flex-flow: row wrap;
  }
`

const StyledVideoContainer = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  width: 100%;
  margin: 25px 0;

  @media(min-width: 768px) {
    margin: 0 0 25px;
  }

  iframe {
    position: absolute; 
    top: 0; 
    left: 0; 
    width: 100%; 
    height: 100%;
  }
`

const StyledPosterImage = styled.img`
  width: 100%;
`

const StyledTextContainer = styled.div`
  font-size: 18px;
  margin-bottom: 25px;
  color: #fff;
  line-height: 1.4;
  text-align: left;
  width: 100%;

  @media(min-width: 768px) {
    width: 60%;
  }

  p {
    margin-bottom: 25px;
  }

  a {
    color: #fff;
    text-decoration: underline;
    transition: color 0.3s ease;
  }

  a:hover {
    color: #ccc;
  }

  img {
    width: 100%;
    max-width: 200px;
  }
`

const NavLinks = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin: 25px 0;

  p {
    width: 100%;
    text-align: center;
    color: #fff;
    margin: 15px 0;
  }
`

const NavLink = styled.div`
  
  a {
    color: #000;
    text-decoration: none;
    display: block;
    border: 3px solid #fff;
    padding: 10px 15px;
    background: #fff;
    transition: all 0.3s ease;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 18px;
    margin: 5px 15px;
  }

  &:hover {
    a {
      color: #fff;
      background: transparent;
      text-decoration: none;
    }
  }
`

const StyledSubmitContainer = styled.div`
  width: 100%;
  margin: 25px 0;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: space-between;
`

const StyledStudentImage = styled.img`
  width: 100%;

  @media(min-width: 768px) {
    width: 60%;
  }
`

const StyledIframeContainer = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  position: relative;

  @media(min-width: 768px) {
    width: 38%;
    padding-bottom: 35%;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const StyledTextTitle = styled.h1`
  font-size: 2.4em;
  font-family: celestia-antiqua-mvb, serif;
  font-weight: 500;
  font-style: normal;
  margin: 25px 0;
  color: #fff;
  text-align: center;
  width: 100%;
`

const StyledContactContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;

  #contact {
    margin: 35px 0;
    padding: 0;
    width: 60%;

    textarea {
      height: 150px;
    }

    a {
      color: #000;
      text-decoration: none;
      display: block;
      border: 3px solid #fff;
      padding: 10px 15px;
      background: #fff;
      transition: all 0.3s ease;
      font-weight: bold;
      text-transform: uppercase;
      font-size: 18px;
      margin: 5px 0;
      text-align: center;
      border-radius: 0;
    }
  
    &:hover {
      a {
        color: #fff;
        background: transparent;
        text-decoration: none;
      }
    }
  }

  img {
    width: 50%;
    height: auto;
  }
`;

const StyledLink = styled.a`
  color: #fff;
  margin: 25px 0;
  padding: 50px 0;
  display: block;
  font-size: 18px;
  transition: color 0.3s ease;

  &:hover {
    color: #ccc;
  }
`

const StyledImageContainer = styled.div`
  width: 100%;

  @media(min-width: 768px) {
    width: 35%;
  }

  img {
    width: 100%;
    margin-bottom: 4px;
  }
`

const TheTerritory = ({ submission }) => {
  document.body.style.backgroundColor = 'black';

  if (submission) {
    setTimeout(() => {  
      const elementTop = document ? document.getElementById('submit').offsetTop : null;

      window.scrollTo(0, elementTop - 60);
    }, 100);
  }

  return (
    <StyledContainer id="rsvp-all-in">
      <StyledPageTitle>
        The Territory
      </StyledPageTitle>
      <StyledPageSubTitle>
        Poster Campaign
      </StyledPageSubTitle>
      <NavLinks>
          {/* <p>Screening has ended, but you can watch the film by clicking below:</p> */}
          <NavLink><AnchorLink href="#submit" offset='60'>Submit Your Concept</AnchorLink></NavLink>
          {/* <NavLink>
            <a href="https://amazonscreenings.com/main/movie_landing/QUxMSU5nbG9iYWxpbmhlcml0YW5jZXB0" target="_blank">7:00 PM (PST)</a>
          </NavLink> */}
          <p>by Friday, September 2nd 2022</p>
      </NavLinks>
      <StyledInner>
        <StyledImageContainer>
          <StyledPosterImage src={PosterImage} />
          <img src="https://s3-us-west-2.amazonaws.com/giweb-prod/program-images/POSTed_ThroughTheirEyes_ButcherBilly.jpg" />
          <img src="https://s3-us-west-2.amazonaws.com/giweb-prod/program-images/POSTedStudio_Coachella_22_IanJepson.jpg" />
          <img src={Image1} />

          <img src={Image4} />
        </StyledImageContainer>
        <StyledTextContainer>
        <StyledVideoContainer>
            <iframe src="https://www.youtube.com/embed/mvbWMKTPLaU" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </StyledVideoContainer>
          <p>
          National Geographic Documentary Films, Picturehouse and Global Inheritance present <strong>POSTed Studio x The Territory</strong>.
          </p>
          <p>
          <strong>Filmmakers, artists and designers have long understood the power of posters to shape the culture of our world by visualizing compelling ideas - like protecting the Amazon - which endure for generations. </strong> 
          </p>
          <p>
          This Summer, Global Inheritance invites audiences everywhere to participate in a special collaborative installment of POSTed Studio - in support of indigenous groups &amp; the Amazon rainforest. 
          </p>
          <p>
          Raise your voice through art to speak on behalf of the Amazon and its fight against large scale farming and ranching, urban development, commercial fishing, climate change, deforestation, logging/mining and the removal and killing of indigenous people. Brave individuals put their lives at stake every day to protect the “refrigerator of the world" but we need your help to spread the message. Share ideas to put a stop to the environmental and social problems impacting this part of the world and promote a more cohesive relationship between humans and the natural world. 
          </p>
          <p>
          <strong>Watch an insightful conversation with the filmmakers of the award-winning new film, THE TERRITORY.  The 15-minute panel features Alex Pritz (Director)</strong>, Gabriel Uchida (Producer), Txai Suruí (Executive Producer) <strong>and Tangãi Uru-eu-wau-wau (Cinematographer, Film Participant)</strong> telling stories and sharing ideas that will inspire..
          </p>
          <p>
          This Q&amp;A is your opportunity to hear from people on the frontline who spent the past three years developing this award-winning documentary. Their unique perspectives and firsthand accounts offer the audience a glimpse into the Uru-eu-wau-wau people's fight to protect their lands and save the rainforest.
          </p>
          <p>
          We invite you to create a poster design inspired by the topics discussed.  It doesn't need to be fancy or finished, but heartfelt, creative and impactful.  Your concept may be chosen to be transformed into a professional poster by the biggest names in art and design from Brazil. The final posters - in both English and Portuguese - will be made available to freely download and display to help raise awareness of these important issues at home and abroad. Submit your artwork via our POSTed Studio homepage by Sept. 2nd <AnchorLink href="#submit" offset='60'>below</AnchorLink>.
          </p>
          <p>
           As a thank you for participating, National Geographic and Picturehouse invite you and a guest to see THE TERRITORY at a theater near you, beginning August 19.  Campaign runs through 9/15.
          </p>
          <p>
          <strong>For information on the film and tickets, please visit:</strong> <a href="https://films.nationalgeographic.com/the-territory#where-to-watch" target="_blank" noreferrer>https://films.nationalgeographic.com/the-territory#where-to-watch</a>
          </p>
          <p>
          <StyledVideoContainer>
            <iframe src="https://www.youtube.com/embed/wL9wvdbk7A4" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </StyledVideoContainer>
          <strong>THE TERRITORY</strong> follows the vital, inspiring fight of the Indigenous Uru-eu-wau-wau people of Brazil to defend their land from non-Indigenous farmers intent on colonizing their protected territory in the Amazon rainforest. Co-produced by the Uru-eu-wau-wau community, the film draws on intimate access to both the Indigenous perspective and the farmers who want their land to chronicle a conflict that has profound implications for the survival of a people and the planet.
          </p>
          <p>
          Partially shot by the Uru-eu-wau-wau people and filmed over the course of several years, THE TERRITORY offers an authentic portrait of an Indigenous community's daily life and struggles. With its breathtaking cinematography showcasing the dramatic landscape and richly textured sound design, the film brings audiences deep into the precious ecosystem they are fighting to protect.
          </p>
          <p>For more information, please email <a href="mailto:info@globalinheritance.org">info@globalinheritance.org</a></p>
          
        </StyledTextContainer>
      </StyledInner>
      
      <StyledTextTitle id="submit">Submit Your Poster Concept</StyledTextTitle>
      <StyledSubmitContainer>
        <StyledStudentImage src={StudentImage} />
        <StyledIframeContainer>
          <iframe className="airtable-embed" src="https://airtable.com/embed/shrRe8fOhsYYQpfFp?backgroundColor=gray" frameBorder="0"  style={{'background': 'transparent', 'border': 'none'}}></iframe>
        </StyledIframeContainer>
      </StyledSubmitContainer>
      <StyledLink href="mailto:info@globalinheritance.org" target="_blank">info@globalinheritance.org</StyledLink>
    </StyledContainer>
  )
}

export default TheTerritory