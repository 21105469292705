import React, { useState } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import Slider from "react-slick";
import get from 'lodash/get'
import logo from '../../spa-logo.png'
import GALLERY_DATA from './examples.json'
import spa3 from '../../spa-header-3.jpg'
import spa1 from '../../spa-header.jpg'
import spaBg from '../../spa-logo-bg.jpg'
import spaTrashy from '../../spa-trashy.png'
import spaTrashyDone from '../../spa-trashy-done.png'
import spaTrashyCrew from '../../trashy-crew.jpg';
import Amazon from '../../images/amazonstudios-logo.png';
import Amblin from '../../images/amblin-logo.png';
import Disney from '../../images/TWDC logo.png'
import Fox from '../../images/fox-logo.png'
import Nbc from '../../images/nbcuniversal-logo.png'
import Netflix from '../../images/Netflix_Logo_RGB_Transparent.png'
import Paramount from '../../images/Paramount.png'
import Participant from '../../images/PARTICIPANT_Primary_Logo_RGB_Black.png'
import Sony from '../../images/Sony Pictures png.png'
import WarnerDiscovery from '../../images/warner-discovery.png';
import PGA from '../../images/PGA Green.png'
import GFSA from '../../images/gfsa.jpg'
import { Link } from 'react-router-dom'
import ExpandButton from '../../expand.svg'
import GalleryModal from '../../components/GalleryModal'

const GlobalStyles = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Glegoo:wght@400;700&display=swap');

  body {
    background-color: #fff;
    color: #000;
  }
`;


const StyledContainer = styled.section`
  width: 100%;
  max-width: 1024px;
  padding: 0 35px;
  margin: 25px auto;
`

const StyledInner = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
`
const StyledHeaderBackground = styled.div`
  width: 100vw;
  height: 375px;
  background-image: url(${spaBg});
  background-size: cover;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  
  img {
    width: 80%;
    max-width: 600px;
    margin: 0 auto;
  }
`;

const StyledText = styled.div`
  font-size: 18px;
  margin-bottom: 25px;
  line-height: 1.4;
  text-align: left;
  width: 100%;

  img {
    width: 100%;
  }

  hr {
    background: #fff;
    width: 200px;
  }

  ${({ small }) => small && `
    font-size: 14px;
    margin: 0;
    padding: 0;
  `}

  ${({ large }) => large && `
    font-size: 22px;
  `}

  ${({ center }) => center && `
    text-align: center;
  `}

  ${({half}) => half && `
    @media(min-width: 768px) {
      width: calc(50% - 25px);
    }
  `}

  ${({ mt }) => mt && `
  
    margin-top: 50px;
  `}

  ul {
    list-style-type: none;

    li {
      margin: 25px 0 35px 0;
    }
  }

  .disc {
    list-style-type: disc;
  }

  .slick-dots {
    bottom: -45px;
  }

  .slick-arrow {
    top: 50%;

    &::before {
      color: #000;
    }
  }
`

const StyledTextTitle = styled.h1`
  font-size: 32px;
  font-family: 'Glegoo', serif;
  font-weight: 500;
  font-style: normal;
  margin: 25px 0;
  text-align: center;
  width: 100%;
  position: relative;

  ${({ left }) => left && `
    text-align: left;
  `}

  &::after {
    content: "";
    width: 50px;
    height: 3px;
    background: #ccc;
    left: 50%;
    bottom: -10px;
    transform: translateX(-50%);
    position: absolute;

    ${({ left }) => left && `
      left: 0;
      transform: none;
    `}
  }
`

const StyledFlex = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
`;

const StyledHowItWorks = styled.div`
  width: 100%;
  padding: 25px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
    margin: 0 auto 20px;
    box-shadow: 0 5px 15px 2px rgb(0 0 0 / 25%)
  }

  @media(min-width: 768px) {
    img {
      width: 75%;
      height: auto;
      max-height: 282px;

      ${({ featured }) => featured && `
        width: 95%;
        max-height: initial;
      `}  
    }

    width: calc(33.333% - 15px);
  }
`;

const StyledListContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  width: 100%;
`

const StyledListItem = styled.li`
  width: calc(90% - 20px);
  position: relative;
  list-style-type: none;

  img {
    width: 100%;
    border: 1px solid #000;
  }

  @media(min-width: 768px) {
    width: 100%;
    padding: 25px;
  }
`

const StyledPartner = styled.div`
  width: calc(100% - 20px);
  margin-bottom: 20px;
  position: relative;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;

  img {
    width: 75%;
    padding: 20px 0;

    ${({ full }) => full && `
      width: 100%;
    `}

    ${({ larger }) => larger && `
      width: 80%;
    `}

    ${({ mid }) => mid && `
      width: 50%;
    `}

    ${({ smaller }) => smaller && `
      width: 40%;
    `}

    ${({ smallest }) => smallest && `
      width: 30%;
    `}
  }

  @media(min-width: 768px) {
    width: calc(20% - 20px);
    height: 100px;
  }
`

const StyledRolloverContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
  left: 0;
  top: 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease;
  opacity: 0;

  @media(min-width: 769px) {
    height: calc(100% - 30px);
    top: 50%;
    left: 50%;
    width: calc(100% - 30px);
    opacity: 0;
    transform: translateY(-50%) translateX(-50%);
  }

  &:hover {
    opacity: 1;
  }
`

const StyledRolloverButton = styled.div`
  width: 65px;
  height: 55px;
  margin: 20px;
  cursor: pointer;

  @media(min-width: 769px) {
    width: 60px;
    height: 60px;
  }

  img {
    width: 100%;
  }
`;

const NavLink = styled.div`
  margin: 15px;  
  a {
    color: #000;
    text-decoration: none;
    display: block;
    border: 3px solid #fff;
    padding: 10px 15px;
    background: #fff;
    transition: all 0.3s ease;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 18px;
    box-shadow: 4px 4px 0 #000;
  }

  &:hover {
    a {
      text-decoration: none;
      color: #333;
      background: #ccc;
    }
  }
`

const SPA = () => {
  const galleryData = get(GALLERY_DATA, 'data', [])
  const activeGalleryPosters = galleryData.find(x => x.name === 'Examples').posters;
  const [activeImageUrl, setActiveImageUrl] = useState('');
  const [showGalleryModal, setShowGalleryModal] = useState(false);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
        }
      }
    ]
  };
  return (
    <>
      <StyledHeaderBackground>
        <img src={logo} alt="Acting on Behalf of Planet Earth" />
        <NavLink><Link to="/spa-request">Request Artwork</Link></NavLink>
      </StyledHeaderBackground>
      <StyledContainer id="poster-submission">
      <GlobalStyles />
      <StyledInner>
        <StyledText/>
        <StyledText>
          Global Inheritance is excited to announce our partnership with the Sustainable Production Alliance (<a href="https://www.greenproductionguide.com/about-the-sustainable-production-alliance/" target="_blank" rel="noreferrer">SPA</a>), Producers Guild of America (<a href="https://www.greenproductionguide.com/about-pga-green/" target="_blank" rel="noreferrer">PGA Green</a>) and Green Film School Alliance (<a href="https://www.greenproductionguide.com/gfsa/" target="_blank" rel="noreferrer">GFSA</a>) to produce a collection of artwork featuring environmental messaging that is purpose-built to appear on-screen in future productions. 
        </StyledText>
        <StyledText>
          <StyledTextTitle>Featured Artwork</StyledTextTitle>
          <Slider {...settings}>
            {activeGalleryPosters.map(gallery => (
              <>
                <StyledListItem key={gallery.thumbUrl}>
                  <img src={gallery.thumbUrl} />
                  <StyledRolloverContainer>
                    <StyledRolloverButton onClick={() => {
                      setActiveImageUrl(gallery.thumbUrl)
                      setShowGalleryModal(true)
                    }}>
                      <img src={ExpandButton} />
                    </StyledRolloverButton>
                  </StyledRolloverContainer>
                </StyledListItem>
                <StyledText center>{gallery.poster}</StyledText>
              </>
            ))}
          </Slider>
        </StyledText>
        
        <StyledTextTitle>About the Program</StyledTextTitle>
        <StyledText>
          <StyledText>
            The film and television industry impacts how we find connection through storytelling, allows us to experience the perspective of diverse communities across the globe, and shapes our understanding of the world around us. This collection of artwork is developed through Global Inheritance's POSTed Studio platform - which is designed to amplify the visibility of important ideas by leveraging collaborative creativity as a source of inspiration and persuasion. 
          </StyledText>
          <StyledText>
            The opportunity to embed these themes on screen provides a unique platform for communicating about environmental issues with audiences everywhere. In partnership with the film and television industry, Global Inheritance intends to develop and share new environmental artwork on an ongoing basis.  
          </StyledText>
          <StyledText>
            To learn more about the POSTed Studio and Global Inheritance, contact <a href="mailto:info@globalinheritnace.org">info@globalinheritnace.org</a>
          </StyledText>
        </StyledText>

        <StyledTextTitle>How it Works</StyledTextTitle>
        <StyledFlex>
          <StyledHowItWorks>
            <img src={spaTrashy} alt="Dont be trashy" />
            <StyledText center>
              Individuals create concepts highlighting social or environmental issues
            </StyledText>
          </StyledHowItWorks>
        
          <StyledHowItWorks featured>
            <img src={spaTrashyDone} alt="Dont be trashy done" />
            <StyledText center>
              Professional artists design a poster inspired by the concept 
            </StyledText>
          </StyledHowItWorks>

          <StyledHowItWorks>
            <img src={spaTrashyCrew} alt="Dont be trashy crew" />
            <StyledText center>
            Use these collaborative artworks in your on screen productions
            </StyledText>
          </StyledHowItWorks>
        </StyledFlex>
        

        <StyledText half large>
          <StyledTextTitle left>Placing POSTed Studio Artwork In Your Upcoming Film/TV productions</StyledTextTitle>

          <ul>
            <li>
              <b>Step 1:</b> Review available POSTed Studio designs &amp; select artwork that suits your needs.
            </li>
            <li>
              <b>Step 2:</b> Fill out and submit our request&nbsp;<Link to="/spa-request">form</Link>.
            </li>
            <li>
              <b>Step 3:</b> Receive access to a high-res version of the requested POSTed Studio artwork for on screen use in your upcoming film or TV project. 
            </li>
          </ul>
        </StyledText>
        <StyledText half>
          <img src={spa1} />
          <img src={spa3} />
        </StyledText>

        <StyledText center mt>
          In partnership with the Sustainable Production Alliance (<a href="https://www.greenproductionguide.com/about-the-sustainable-production-alliance/" target="_blank" rel="noreferrer">SPA</a>), Producers Guild of America (<a href="https://www.greenproductionguide.com/about-pga-green/" target="_blank" rel="noreferrer">PGA Green</a>)<br />and Green Film School Alliance (<a href="https://www.greenproductionguide.com/gfsa/" target="_blank" rel="noreferrer">GFSA</a>). 
        </StyledText>
        <StyledListContainer>
          <StyledPartner>
            <img src={PGA} />
          </StyledPartner>
          <StyledPartner>
            <img src={GFSA} />
          </StyledPartner>
        </StyledListContainer>

        <StyledListContainer>
          <StyledPartner>
            <img src={Amazon} />
          </StyledPartner>
          <StyledPartner mid>
            <img src={Amblin} />
          </StyledPartner>
          <StyledPartner>
            <img src={Disney} />
          </StyledPartner>
          <StyledPartner smaller>
            <img src={Fox} />
          </StyledPartner>
          <StyledPartner>
            <img src={Nbc} />
          </StyledPartner>
          <StyledPartner mid>
            <img src={Netflix} />
          </StyledPartner>
          <StyledPartner full>
            <img src={Paramount} />
          </StyledPartner>
          <StyledPartner>
            <img src={Participant} />
          </StyledPartner>
          <StyledPartner smallest>
            <img src={Sony} />
          </StyledPartner>
          <StyledPartner>
            <img src={WarnerDiscovery} />
          </StyledPartner>
        </StyledListContainer>
      </StyledInner>
      {showGalleryModal && (
          <GalleryModal 
            toggle={() => setShowGalleryModal(!showGalleryModal)}
            imgUrl={activeImageUrl}
          />
        )}            
    </StyledContainer>
    </>
  )
}

export default SPA