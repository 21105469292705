import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'

const StyledImageContainer = styled.div`
  position: relative;

  ${({half}) => half && `
    @media(min-width: 768px) {
      display: flex;
      flex-flow: row wrap;
    }
  `}

  img {
    width: 100%;

    ${({half}) => half && `
      @media(min-width: 768px) {
        width: calc(50% - 25px);
      }
  `}
  }
`

const StyledText = styled.p`
  font-size: 18px;
  margin-bottom: 25px;
  line-height: 1.4;
  text-align: left;
  width: 100%;

  img {
    float: left;
    width: 30%;
    padding: 0 15px 15px 0;
  }

  ${({half}) => half && `
    @media(min-width: 768px) {
      width: calc(50% - 25px);
    }
  `}
`

const ArtistModal = ({
 toggle, imgUrl, desc
}) => {
  return (
    <Modal 
      isOpen
      toggle={toggle}
      size={'lg'}
    >
      <ModalHeader toggle={toggle} />
      <ModalBody>
        <StyledImageContainer half={Boolean(desc)}>
          {!desc && <img src={imgUrl} />}
          {desc && <StyledText dangerouslySetInnerHTML={{__html: desc}} />}
        </StyledImageContainer>
      </ModalBody>
    </Modal>
  );
};

ArtistModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  imgUrl: PropTypes.string.isRequired,
};

export default ArtistModal;
