import React, { useState } from 'react'
import styled from 'styled-components'
import ReactPlayer from 'react-player'
import introCover from '../../intro-cover.png'

const StyledContainer = styled.div`
  position: relative; 
  padding-bottom: 56.25%; 
  height: 0; 
  overflow: hidden; 
  max-width: 100%;

  iframe {
    position: absolute; 
    top: 0; 
    left: 0; 
    width: 100%; 
    height: 100%;
  }
`

const StyledCover = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(${introCover});
  background-size: cover;
  background-repeat: no-repeat;
  pointer-events: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;

  &::after {
    content: "\f144";
    font-size: 100px;
    font-family: "FontAwesome";
    color: white;
  }

  ${({ isPlaying }) => isPlaying && `
    display: none;
  `}
`

const IntroVideo = () => {
  const [isPlaying, setIsPlaying] = useState(false)
  document.body.style.backgroundColor = '#292929';
  return (
    <StyledContainer>
      <ReactPlayer 
        url={'https://www.youtube.com/embed/rkFp_p_KOoE'}
        onPlay={() => setIsPlaying(true)}
        onPause={() => setIsPlaying(false)}
      />
      <StyledCover isPlaying={isPlaying} />
    </StyledContainer>
  )
}

export default IntroVideo