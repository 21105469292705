import React, { useState } from 'react'
import styled from 'styled-components'
import get from 'lodash/get'
import Mailchimp from '../../components/Mailchimp'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import GalleryModal from '../../components/GalleryModal'
import StudentsImage1 from '../../rsvp-submit-students.jpg'
import GALLERY_DATA from './examples.json'
import ExpandButton from '../../expand.svg'
import DownloadButton from '../../download.svg'

const StyledContainer = styled.section`
  width: 100%;
  max-width: 992px;
  padding: 0 35px;
  margin: 25px auto;
`

const StyledInner = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
`

const NavLinks = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin: 25px 0 45px;
`

const NavLink = styled.div`
  margin-left: 35px;
  
  a {
    color: #000;
    text-decoration: none;
    display: block;
    border: 3px solid #fff;
    padding: 10px 15px;
    background: #fff;
    transition: all 0.3s ease;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 18px;
  }

  &:hover {
    a {
      color: #fff;
      background: transparent;
      text-decoration: none;
    }
  }
`

const StyledText = styled.div`
  font-size: 18px;
  margin-bottom: 25px;
  color: #fff;
  line-height: 1.4;
  text-align: left;
  width: 100%;

  ${({half}) => half && `
    @media(min-width: 768px) {
      width: calc(50% - 25px);
    }
  `}

  ul {
    list-style-type: decimal;

    li {
      margin: 0 0 15px 45px;
    }
  }

  .disc {
    list-style-type: disc;
  }
`

const StyledTextTitle = styled.h1`
  font-size: 32px;
  font-family: celestia-antiqua-mvb, serif;
  font-weight: 500;
  font-style: normal;
  margin: 25px 0;
  color: #fff;
  text-align: center;
  width: 100%;

`

const StyledStudentsImage = styled.img`
  width: 100%;
`

const StyledListContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  width: 100%;
`

const StyledListItem = styled.li`
  width: calc(100% - 20px);
  margin-bottom: 120px;
  position: relative;

  img {
    width: 100%;
  }

  @media(min-width: 768px) {
    width: calc(50% - 20px);
  }
`

const StyledRolloverContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 80px;
  background: rgba(0,0,0,0.5);
  top: 100%;
  left: 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease;

  @media(min-width: 769px) {
    height: 95.45%;
    top: 16px
    opacity: 0;
  }

  &:hover {
    opacity: 1;
  }
`

const StyledRolloverButton = styled.div`
  width: 35px;
  height: 35px;
  margin: 20px;
  cursor: pointer;

  @media(min-width: 769px) {
    width: 50px;
    height: 50px;
  }

  img {
    width: 100%;
  }
`

const PosterSubmission = () => {
  const [showGalleryModal, setShowGalleryModal] = useState(false)
  const [activeImageUrl, setActiveImageUrl] = useState('')
  const galleryData = get(GALLERY_DATA, 'data', [])
  const activeGalleryPosters = galleryData.find(x => x.name === 'Examples').posters
  // document.body.style.backgroundColor = 'black';

  return (
    <StyledContainer id="poster-submission">
      <StyledStudentsImage src={StudentsImage1} />
      <NavLinks>
        <NavLink><AnchorLink href="#submit" offset='60'>Submit</AnchorLink></NavLink>
        <NavLink><AnchorLink href="#sign-up" offset='60'>Sign Up</AnchorLink></NavLink>
      </NavLinks>
      <StyledInner>
        <StyledText>
          We are producing a special edition of the POSTed Studio online to encourage active participation in democracy through voting by creating a new series of collaborative posters. The collaboration begins with people like you submitting concepts that capture your beliefs on the issue. A select group of the submitted concepts will be transformed by professional artists into official POSTed Editions, all of which will be available to download for free in time for the 2020 election.
        </StyledText>
        <StyledText>
          Upload the poster concepts you’ve come up with by this coming Friday, 9/25. You can submit as many ideas a you want to share with the world, but they must be received prior to the deadline for consideration.
        </StyledText>
        <StyledText half>
          <StyledTextTitle>Prizes will be awarded for the most creative poster concepts:</StyledTextTitle>
          <ul class="disc">
            <li>
              Penny Skateboards 
            </li>
            <li>
              Global Inheritance Shirts
            </li>
            <li>
              The North Face Winter Jackets 
            </li>
            <li>
              Shwood Sunglasses
            </li>
            <li>
              Beats By Dre Headphones
            </li>
            <li>
              Jansport Hiking Backpack 
            </li>
            <li>
              $300 Bike Shop Gift Certificate 
            </li>
            <li>
              Adobe Creative Cloud Year Membership 
            </li>
            <li>
              + More
            </li>
          </ul>
        </StyledText>
        
        <StyledText half>
          <StyledTextTitle>How the POSTed Studio works</StyledTextTitle>
          <ul>
            <li>
              <b>Create</b> a poster concept [drawing, collage, painting, etc…] inspired by the theme: <b>What Would Make You Want To Vote?</b>
            </li>
            <li>
              <b>Submit</b> your concept below by uploading a scan or photo of your concept.
            </li>
            <li>
              <b>Poster concepts</b> will be added to an <b>online gallery.</b>
            </li>
            <li>
              <b>Partnering artists</b> will choose their favorite concepts [based on the idea/message not the quality of art] and will then transform the concept into an official POSTed Edition.
            </li>
            <li>
            The professionally designed posters will be will be <b>available to download for free</b>.
            </li>
            <li>
            The online gallery showcase will be on October 5th.
            </li>
          </ul>
        </StyledText>
        <StyledText><b>Set a reminder to make sure your concepts reach us prior to the deadline: 
September 25th at 6pm PST.</b></StyledText>
        <StyledText>
          <i>The POSTed Studio campaign is designed to collaborate and share the voices of our community. Everyone at Global Inheritance believes in the power of people to effect change, and it begins with each of us. Share this message and invite others you know to participate.</i>
        </StyledText>
        <StyledTextTitle><b>The only path to a more sustainable an equitable future is the one we create together.</b></StyledTextTitle>
        <StyledText>
          <StyledTextTitle id="submit">Submit your concept</StyledTextTitle>
          <iframe className="airtable-embed" src="https://airtable.com/embed/shrA7ezcYnljouWJI?backgroundColor=gray" frameBorder="0"  width="100%" height="533" style={{'background': 'transparent', 'border': 'none'}}></iframe>
        </StyledText>

        <StyledTextTitle id="examples">Examples</StyledTextTitle>
        <StyledListContainer>
          {activeGalleryPosters.map(gallery => (
            <StyledListItem key={gallery.thumbUrl}>
              <img src={gallery.thumbUrl} />
              <StyledRolloverContainer>
                <StyledRolloverButton onClick={() => {
                  setActiveImageUrl(gallery.thumbUrl)
                  setShowGalleryModal(true)
                }}>
                  <img src={ExpandButton} />
                </StyledRolloverButton>
                <StyledRolloverButton>
                  <a href={gallery.posterUrl} target='_blank'>
                    <img src={DownloadButton} />
                  </a>
                </StyledRolloverButton>
              </StyledRolloverContainer>
            </StyledListItem>
          ))}
        </StyledListContainer>
        {showGalleryModal && (
          <GalleryModal 
            toggle={() => setShowGalleryModal(!showGalleryModal)}
            imgUrl={activeImageUrl}
          />
        )}

        <Mailchimp
          tag='sign-up'
          title='Sign Up'
          messages={{
            button: 'Submit'
          }}
          action='https://globalinheritance.us2.list-manage.com/subscribe/post?u=40e700f186279051f3a238f8a&amp;id=ac44d8926b'
          fields={[
            {
              name: 'EMAIL',
              placeholder: 'Email',
              type: 'email',
              required: true,
              width: '100%',
            },
            {
              name: 'FNAME',
              placeholder: 'First Name',
              type: 'text',
              required: true,
              width: 'calc(50% - 10px)',
            },
            {
              name: 'LNAME',
              placeholder: 'Last Name',
              type: 'text',
              required: true,
              width: 'calc(50% - 10px)'
            },
            {
              name: 'group[268][1]',
              placeholder: 'Artist?',
              type: 'checkbox',
              label: `I'm an artist interested in creating a redesign`
            }
          ]}
        /> 

        {/* <StyledText>
          <StyledTextTitle>POSTed Studio Terms and Conditions</StyledTextTitle>
          <i>The Work of Art may be derived in part from a preexisting-work, the copyright in which preexisting work will be owned by or exclusively licensed to Global Inheritance. Artist understands that it is creating the Works of Art for the benefit of Global Inheritance. For good and valuable consideration, the receipt of which Artist acknowledges, Artist assigns to Global Inheritance any and all of its right, title, and interest in and to the Works of Arts, including the undivided copyrights and all other rights of any kind therein throughout the world, whether now known or hereafter recognized or created.</i>
        </StyledText> */}
      </StyledInner>

    </StyledContainer>
  )
}

export default PosterSubmission