import React, { useState } from 'react'
import styled from 'styled-components'
import get from 'lodash/get'
import Mailchimp from '../../components/Mailchimp'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import GalleryModal from '../../components/GalleryModal'
import StudentsImage1 from '../../students-1.png'
import GALLERY_DATA from './examples.json'
import ExpandButton from '../../expand.svg'
import DownloadButton from '../../download.svg'

const StyledContainer = styled.section`
  width: 100%;
  max-width: 992px;
  padding: 0 35px;
  margin: 25px auto;
`

const StyledInner = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
`

const NavLinks = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin: 25px 0 45px;
`

const NavLink = styled.div`
  margin-left: 35px;
  
  a {
    color: #000;
    text-decoration: none;
    display: block;
    border: 3px solid #fff;
    padding: 10px 15px;
    background: #fff;
    transition: all 0.3s ease;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 18px;
  }

  &:hover {
    a {
      color: #fff;
      background: transparent;
      text-decoration: none;
    }
  }
`

const StyledText = styled.div`
  font-size: 18px;
  margin-bottom: 25px;
  color: #fff;
  line-height: 1.4;
  text-align: left;
  width: 100%;

  ${({half}) => half && `
    @media(min-width: 768px) {
      width: calc(50% - 25px);
    }
  `}

  ul {
    list-style-type: decimal;

    li {
      margin: 0 0 15px 45px;
    }
  }
`

const StyledTextTitle = styled.h1`
  font-size: 32px;
  font-family: celestia-antiqua-mvb, serif;
  font-weight: 500;
  font-style: normal;
  margin: 25px 0;
  color: #fff;
  text-align: center;
  width: 100%;

`

const StyledStudentsImage = styled.img`
  width: 100%;
`

const StyledListContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  width: 100%;
`

const StyledListItem = styled.li`
  width: calc(100% - 20px);
  margin-bottom: 120px;
  position: relative;

  img {
    width: 100%;
  }

  @media(min-width: 768px) {
    width: calc(50% - 20px);
  }
`

const StyledRolloverContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 80px;
  background: rgba(0,0,0,0.5);
  top: 100%;
  left: 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease;

  @media(min-width: 769px) {
    height: 95.45%;
    top: 16px
    opacity: 0;
  }

  &:hover {
    opacity: 1;
  }
`

const StyledRolloverButton = styled.div`
  width: 35px;
  height: 35px;
  margin: 20px;
  cursor: pointer;

  @media(min-width: 769px) {
    width: 50px;
    height: 50px;
  }

  img {
    width: 100%;
  }
`

const StudentsForJustice = () => {
  const [showGalleryModal, setShowGalleryModal] = useState(false)
  const [activeImageUrl, setActiveImageUrl] = useState('')
  const galleryData = get(GALLERY_DATA, 'data', [])
  const activeGalleryPosters = galleryData.find(x => x.name === 'Examples').posters
  document.body.style.backgroundColor = 'black';

  return (
    <StyledContainer id="students-for-justice">
      <StyledStudentsImage src={StudentsImage1} />
      <NavLinks>
        <NavLink><AnchorLink href="#submit" offset='60'>Submit</AnchorLink></NavLink>
        <NavLink><AnchorLink href="#sign-up" offset='60'>Sign Up</AnchorLink></NavLink>
      </NavLinks>
      <StyledInner>
        <StyledText half>
          <b>The current state:</b> The racial injustices that people of color, indigenous peoples, and other historically marginalized populations face everyday is undeniable. Systematic racism has allowed oppression, inequality, and violence on black and brown communities across our country to persist for too long. Racial disparities appear in many forms including but not limited to policing, incarceration, food systems, and basic access to resources like healthcare, education, and job opportunities. As long as these systems exist, the lives of people of color will remain in a state of emergency. 
        </StyledText>
        <StyledText half>
          <b>For these reasons,</b> Student CTA is collaborating with Global Inheritance to launch POSTed STUDIO: Students For Justice. Students across California colleges and universities are invited to use this program to share their message on racial inequality and social justice. Whether or not you are able to make donations or make it out to the streets to protest, art is another powerful way to show your support and influence change. With this POSTed STUDIO campaign, we want to open channels for dialogue and inspire actions that will lead to a reformation of what it means to be a person of color and what it means to achieve justice. 
        </StyledText>
        <StyledText>
          <StyledTextTitle>How the POSTed Studio works</StyledTextTitle>
          <ul>
            <li>
              <b>Create</b> a poster concept [drawing, collage, painting, etc…] inspired by the theme: <b>Social &amp; Racial Justice.</b>
            </li>
            <li>
              <b>Submit</b> your concept online by visiting the <b>POSTed Studio: Students For Justice</b> website and uploading a scan or photo of your concept.
            </li>
            <li>
              <b>Poster concepts</b> will be added to an <b>online gallery and updated every week with a handful of concepts spotlighted each week on the main page.</b>
            </li>
            <li>
              <b>During the 2020/2021 School Year,</b> partnering <b>artists and designers</b> will choose their <b>favorite concepts</b> [based on the strength of the idea not the quality of art] and <b>create a POSTed Edition inspired by the selected concept.</b>
            </li>
            <li>
              <b>All POSTed Editions</b> will be added to our online gallery and available for a Free download.
            </li>
          </ul>
        </StyledText>
        <StyledText>
          <i>The Posted Studio campaign is designed to collaborate and share the voices of our community. Global Inheritance’s campaign with the Student CTA is a unique collaboration for California students. If you are a student in California, please join us and share your message. If you are not a California student, we will have opportunities for you to get involved in the near future.</i>
        </StyledText>
        <StyledText>
          <StyledTextTitle id="submit">Submit your concept</StyledTextTitle>
          <iframe className="airtable-embed" src="https://airtable.com/embed/shrFE1sNWbJTLeZIE?backgroundColor=gray" frameBorder="0"  width="100%" height="533" style={{'background': 'transparent', 'border': 'none'}}></iframe>
        </StyledText>

        <StyledTextTitle id="examples">Examples</StyledTextTitle>
        <StyledListContainer>
          {activeGalleryPosters.map(gallery => (
            <StyledListItem key={gallery.thumbUrl}>
              <img src={gallery.thumbUrl} />
              <StyledRolloverContainer>
                <StyledRolloverButton onClick={() => {
                  setActiveImageUrl(gallery.thumbUrl)
                  setShowGalleryModal(true)
                }}>
                  <img src={ExpandButton} />
                </StyledRolloverButton>
                <StyledRolloverButton>
                  <a href={gallery.posterUrl} target='_blank'>
                    <img src={DownloadButton} />
                  </a>
                </StyledRolloverButton>
              </StyledRolloverContainer>
            </StyledListItem>
          ))}
        </StyledListContainer>
        {showGalleryModal && (
          <GalleryModal 
            toggle={() => setShowGalleryModal(!showGalleryModal)}
            imgUrl={activeImageUrl}
          />
        )}

        <Mailchimp
          tag='sign-up'
          title='Sign Up'
          messages={{
            button: 'Submit'
          }}
          action='https://globalinheritance.us2.list-manage.com/subscribe/post?u=40e700f186279051f3a238f8a&amp;id=ac44d8926b'
          fields={[
            {
              name: 'EMAIL',
              placeholder: 'Email',
              type: 'email',
              required: true,
              width: '100%',
            },
            {
              name: 'FNAME',
              placeholder: 'First Name',
              type: 'text',
              required: true,
              width: 'calc(50% - 10px)',
            },
            {
              name: 'LNAME',
              placeholder: 'Last Name',
              type: 'text',
              required: true,
              width: 'calc(50% - 10px)'
            },
            {
              name: 'group[268][1]',
              placeholder: 'Artist?',
              type: 'checkbox',
              label: `I'm an artist interested in creating a redesign`
            }
          ]}
        /> 
        

        {/* <StyledText>
          <StyledTextTitle>POSTed Studio Terms and Conditions</StyledTextTitle>
          <i>The Work of Art may be derived in part from a preexisting-work, the copyright in which preexisting work will be owned by or exclusively licensed to Global Inheritance. Artist understands that it is creating the Works of Art for the benefit of Global Inheritance. For good and valuable consideration, the receipt of which Artist acknowledges, Artist assigns to Global Inheritance any and all of its right, title, and interest in and to the Works of Arts, including the undivided copyrights and all other rights of any kind therein throughout the world, whether now known or hereafter recognized or created.</i>
        </StyledText> */}
      </StyledInner>

    </StyledContainer>
  )
}

export default StudentsForJustice