import React from 'react'
import styled from 'styled-components'
import PosterImage from '../../all-in-poster.png'
import StudentImage from '../../rsvp-submit-students.jpg'
import GlobalInheritanceLogo from '../../global-inheritance-logo.png'
import Contact from '../Contact'


const StyledContainer = styled.section`
  width: 100%;
  max-width: 1370px;
  padding: 25 35px;
  margin: 25px auto;
`

const StyledPageTitle = styled.h1`
  margin-bottom: 10px;
  font-size: 3.2em;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  line-height: 1;

  @media(min-width: 768px) {
    margin-bottom: 0;
    font-size: 3.8em;
  }
`

const StyledPageSubTitle = styled.h2`
  margin-bottom: 25px;
  font-size: 2.4em;
  text-align: center;
  color: #fff;
  font-family: celestia-antiqua-mvb, serif;
`

const StyledRule = styled.hr`
  background: #fff;
  width: 400px;
`

const StyledInner = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin: 0 auto 25px auto;
`

const StyledVideoContainer = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  width: 100%;
  margin: 25px 0;

  @media(min-width: 768px) {
    margin: 0 0 25px;
  }

  iframe {
    position: absolute; 
    top: 0; 
    left: 0; 
    width: 100%; 
    height: 100%;
  }
`

const StyledPosterImage = styled.img`
  width: 100%;

  @media(min-width: 768px) {
    width: 35%;
  }
`

const StyledTextContainer = styled.div`
  font-size: 18px;
  margin-bottom: 25px;
  color: #fff;
  line-height: 1.4;
  text-align: left;
  width: 100%;

  @media(min-width: 768px) {
    width: 60%;
  }

  p {
    margin-bottom: 25px;
  }

  a {
    color: #fff;
    text-decoration: underline;
    transition: color 0.3s ease;
  }

  a:hover {
    color: #ccc;
  }

  img {
    width: 100%;
    max-width: 200px;
  }
`

const NavLinks = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin: 25px 0;

  p {
    width: 100%;
    text-align: center;
    color: #fff;
    margin: 15px 0;
  }
`

const NavLink = styled.div`
  
  a {
    color: #000;
    text-decoration: none;
    display: block;
    border: 3px solid #fff;
    padding: 10px 15px;
    background: #fff;
    transition: all 0.3s ease;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 18px;
    margin: 5px 15px;
  }

  &:hover {
    a {
      color: #fff;
      background: transparent;
      text-decoration: none;
    }
  }
`

const StyledSubmitContainer = styled.div`
  width: 100%;
  margin: 25px 0;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: space-between;
`

const StyledStudentImage = styled.img`
  width: 100%;

  @media(min-width: 768px) {
    width: 60%;
  }
`

const StyledIframeContainer = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  position: relative;

  @media(min-width: 768px) {
    width: 38%;
    padding-bottom: 35%;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const StyledTextTitle = styled.h1`
  font-size: 2.4em;
  font-family: celestia-antiqua-mvb, serif;
  font-weight: 500;
  font-style: normal;
  margin: 25px 0;
  color: #fff;
  text-align: center;
  width: 100%;
`

const StyledContactContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;

  #contact {
    margin: 35px 0;
    padding: 0;
    width: 60%;

    textarea {
      height: 150px;
    }

    a {
      color: #000;
      text-decoration: none;
      display: block;
      border: 3px solid #fff;
      padding: 10px 15px;
      background: #fff;
      transition: all 0.3s ease;
      font-weight: bold;
      text-transform: uppercase;
      font-size: 18px;
      margin: 5px 0;
      text-align: center;
      border-radius: 0;
    }
  
    &:hover {
      a {
        color: #fff;
        background: transparent;
        text-decoration: none;
      }
    }
  }

  img {
    width: 50%;
    height: auto;
  }
`;

const StyledLink = styled.a`
  color: #fff;
  margin: 25px 0;
  padding: 50px 0;
  display: block;
  font-size: 18px;
  transition: color 0.3s ease;

  &:hover {
    color: #ccc;
  }
`

const RSVPAllIn = ({ submission }) => {
  document.body.style.backgroundColor = 'black';

  if (submission) {
    setTimeout(() => {
      const elementTop = document.getElementById('submit').offsetTop;
      window.scrollTo(0, elementTop - 60);
    }, 100);
  }

  return (
    <StyledContainer id="rsvp-all-in">
      <StyledPageTitle>
        All In: The Fight for Democracy
      </StyledPageTitle>
      <StyledPageSubTitle>
        Early Screening RSVP
      </StyledPageSubTitle>
      <NavLinks>
          <p>Screening has ended, but you can watch the film by clicking below:</p>
          <NavLink>
            <a href="https://www.amazon.com/All-Fight-Democracy-Stacey-Abrams/dp/B08FRV55VB/ref=sr_1_3?dchild=1&keywords=All+In+movie&qid=1600471995&sr=8-3" target="_blank">All In: The Fight for Democracy</a>
          </NavLink>
          {/* <NavLink>
            <a href="https://amazonscreenings.com/main/movie_landing/QUxMSU5nbG9iYWxpbmhlcml0YW5jZXB0" target="_blank">7:00 PM (PST)</a>
          </NavLink> */}
          {/* <p>Wednesday September 16, 2020</p> */}
      </NavLinks>
      <StyledInner>
        <StyledPosterImage src={PosterImage} />
        <StyledTextContainer>
        <StyledVideoContainer>
          <iframe src="https://www.youtube.com/embed/t6jVGswLPd8" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </StyledVideoContainer>
          <p>
            In anticipation of the 2020 election cycle, Global Inheritance, the Student California Teachers Association are partnering with Stacey Abrams in support of a free online screening of ALL IN: THE FIGHT FOR DEMOCRACY. This upcoming documentary examines the history of voting rights and the often overlooked yet insidious issue of voter suppression in modern America. It offers an insider’s look into the current structure of US election law and the expanding barriers to voting that unjustly impact an increasingly large proportion of US citizens in communities across the country. Throughout the film, Stacey Abrams shares personal stories, unique perspectives, and expertise. 
          </p>
          <p>
            We are encouraging everyone to use the film as an inspiration to capture their own thoughts and beliefs about voting and democracy in the format of visual poster concepts. The experience of translating your opinions into a language that communicates without speaking is a deeply creative process - offering new insights about advocacy, language, persuasion, and artistic collaboration. This activity can be integrated into class assignments covering the history of democracy and voting rights in the United States.
          </p>
          <p>
            Prizes will be awarded for the most creative poster concepts. A select group of the submitted concepts will be transformed by professional artists into official POSTed Editions - powerful artwork amplifying the voice of America’s youth and their educators.  All POSTed Editions will be available to download for free in time for the 2020 election.
          </p>
          <p>
            Sign up for the screening today and learn more by visiting our webpage! All schools and individual classroom teachers are invited to direct message us if they are interested in partaking in this program. 
          </p>
          <p>For more information, please email <a href="mailto:info@globalinheritance.org">info@globalinheritance.org</a></p>
          <p>
            Global Inheritance - <a href="http://www.globalinheritance.org/" target="_blank">https://www.globalinheritance.org/</a>
            <br/>
            All In: The Fight For Democracy = <a href="https://www.allinforvoting.com" target="_blank">https://www.allinforvoting.com</a>
          </p>
          <StyledContactContainer>
            <Contact />
            <img src={GlobalInheritanceLogo} />
          </StyledContactContainer>
          

        </StyledTextContainer>
      </StyledInner>
      
      <StyledTextTitle id="submit">Submit Your Poster Concept</StyledTextTitle>
      <StyledSubmitContainer>
        <StyledStudentImage src={StudentImage} />
        <StyledIframeContainer>
          <iframe className="airtable-embed" src="https://airtable.com/embed/shrA7ezcYnljouWJI?backgroundColor=gray" frameBorder="0"  style={{'background': 'transparent', 'border': 'none'}}></iframe>
        </StyledIframeContainer>
      </StyledSubmitContainer>
      <StyledLink href="mailto:info@globalinheritance.org" target="_blank">info@globalinheritance.org</StyledLink>
    </StyledContainer>
  )
}

export default RSVPAllIn