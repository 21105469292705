import React, { useState } from 'react'
import styled from 'styled-components'
import get from 'lodash/get'
import SectionHeader from '../../components/SectionHeader'
import ShopModal from '../../components/ShopModal'
import SHOP_DATA from './shop.json'
import { Button } from 'reactstrap'
import ExpandButton from '../../expand.svg';
import DownloadButton from '../../download.svg';
import { AddToCart } from 'react-snipcart'


const StyledShopContainer = styled.section`
  width: 100%;
  max-width: 992px;
  padding: 0 35px;
  margin: 100px auto;
`

const StyledListContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  width: 100%;
`

const StyledListItem = styled.li`
  width: calc(100% - 20px);
  margin-bottom: 20px;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-position: center center;
    object-fit: cover;
  }

  @media(min-width: 768px) {
    width: calc(33.33% - 20px);
  }
`

const StyledRolloverContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
  top: 16px;
  left: 0;
  opacity: 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`

export const StyledShopInfo = styled.div`
  position: relative;
  color: #fff;
  font-size: 18px;
  padding: 15px;

  h1 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 25px;
  }

  p {
    font-size: 24px;
    font-family: celestia-antiqua-mvb, serif;
    font-weight: 400;
    font-style: normal;
  }
`

const Shop = () => {
  const [showShopModal, setShowShopModal] = useState(false)
  const [activeShopData, setActiveShopData] = useState({})

  const shopData = get(SHOP_DATA, 'data', [])
  
  return (
    <StyledShopContainer id="shop">
      <SectionHeader section="Shop" />
      <StyledListContainer>
        {shopData.map(shop => (
          <StyledListItem key={shop.title}>
            <img src={shop.image} />
            <StyledRolloverContainer 
              onClick={() => {
                setActiveShopData(shop)
                setShowShopModal(true)
              }}
            >
              <StyledShopInfo>
                <h1>{shop.title}</h1>
                <p>${shop.price}</p>
              </StyledShopInfo>
            </StyledRolloverContainer>
          </StyledListItem>
        ))}
      </StyledListContainer>

      {showShopModal && (
        <ShopModal 
          toggle={() => setShowShopModal(!showShopModal)}
          shopData={activeShopData}
          close={() => setShowShopModal(false)}
        />
      )}
  </StyledShopContainer>
  )
}

export default Shop