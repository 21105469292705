import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap'
import ShopDetails from '../ShopModalDetails'

const StyledInner = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
`

const StyledImageContainer = styled.div`
  position: relative;
  width: 100%;
  
  @media(min-width: 768px) {
    width: calc(50% - 25px);
  }

  img {
    width: 100%;
  }
`

const StyledDetailsContainer = styled.div`
  display: flex;
  flex-flow: column;
  width: 50%;
`

const StyledSelect = styled.select`
  display: block;
  font-size: 16px;
  font-family: sans-serif;
  font-weight: 700;
  color: #444;
  line-height: 1.3;
  padding: .6em 1.4em .5em .8em;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0 0 25px 0;
  border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
  border-radius: .5em;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
    linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);
  background-repeat: no-repeat, repeat;
  background-position: right .7em top 50%, 0 0;
  background-size: .65em auto, 100%;
`

const ShopModal = ({
 toggle, shopData, close
}) => {
  const [activeShopData, setActiveShopData] = useState(shopData.length ? shopData[0] : shopData)

  return (
    <Modal 
      isOpen
      toggle={toggle}
      size='lg'
    >
      <ModalHeader toggle={toggle} />
      <ModalBody>
        <StyledInner>
          <StyledImageContainer>
            <img src={activeShopData.image} />
          </StyledImageContainer>
          <StyledDetailsContainer>
            {shopData.length && (
              <div>
                <p>Select an item to print on:</p>
                <StyledSelect onChange={(e) => setActiveShopData(shopData[e.target.value])}>
                  {shopData.map((option, i) => (
                    <option key={i} value={i}>{option.title}</option>
                  ))}
                </StyledSelect>
              </div>
            )}
            <ShopDetails shopData={activeShopData} close={close} />
          </StyledDetailsContainer>
        </StyledInner>
      </ModalBody>
    </Modal>
  );
  
};

ShopModal.propTypes = {
  toggle: PropTypes.func.isRequired,
};

export default ShopModal;
