import React from 'react'
import styled from 'styled-components'
import SectionHeader from '../../components/SectionHeader'
import AboutImage1 from '../../design-1-alt.jpeg'

const StyledAboutContainer = styled.section`
  width: 100%;
  max-width: 992px;
  padding: 0 35px;
  margin: 100px auto;
`

const StyledAboutInner = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
`

const StyledLeftContainer = styled.div`
  width: 100%;
  text-align: left;
  color: #fff;

  @media(min-width: 768px) {
    width: calc(50% - 25px);
  }
`

const StyledRightContainer = styled.div`
  width: 100%;

  @media(min-width: 768px) {
    width: calc(50% - 25px);
  }
`

const StyledText = styled.p`
  font-size: 18px;
  margin-bottom: 25px;
  line-height: 1.4;
`

const StyledAboutImage = styled.img`
  width: 100%;
`

const About = () => (
  <StyledAboutContainer id="about">
    <SectionHeader section="About" titleText="POSTed Studio Education Through Poster Art" />

    <StyledAboutInner>
      <StyledLeftContainer>
        <StyledText>
          Posters have helped shape the culture of our world by visualizing our 
          collective messages of hope, optimism, change, and empowerment. The POSTed Studio 
          is an interactive, collaborative program that brings together inspired individuals and 
          professional designers to create powerful posters around the world’s greatest challenges.
        </StyledText>
        <StyledText>
          At the Studio, individuals design poster concepts around social or environmental issues 
          that matter to them. The most creative concepts are then transformed by professional designers
          and are made available to share with the rest of the world.
        </StyledText>
        <StyledText>
          POSTed amplifies the power of visual communication to everyone, regardless of artstic skill. 
          Equally an open forum for great ideas and a creative space for artistic messaging, POSTed Studio 
          educates and inspires our communities to tackle the most important global issues of our time.
        </StyledText>
      </StyledLeftContainer>
      <StyledRightContainer>
        <StyledAboutImage src={AboutImage1} />
      </StyledRightContainer>
    </StyledAboutInner>
  
  </StyledAboutContainer>
)

export default About