import React, { useState } from 'react'
import { Link, withRouter } from 'react-router-dom'
import styled from 'styled-components'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import logoImg from '../../logo.png'
import giLogoImg from '../../gi-logo.png'

const StyledNavContainer = styled.nav`
  width: 100%;
  height: 60px;
  position: sticky;
  top: -1px;
  left: 0;
  background-color: #292929;
  z-index: 100;

  @media(min-width: 494px) {
    height: 45px;
  }
`

const StyledNavInner = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  flex-flow: row wrap;
  height: 100%;
  position: relative;
  justify-content: space-between;
  align-items: center;
  padding: 0 35px;
`

const StyledNavLogoContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  color: #fff;
`

const Logo = styled.img`
  width: 190px;
  height: 28px;
  margin-right: 15px;
`

const GiLogo = styled.img`
  width: 120px;
  margin-right: 50px

  @media(min-width: 494px) {
    margin-left: 10px;
  }
`

const NavLinks = styled.div`
  display: none;
  flex-flow: row wrap;

  @media(min-width: 1280px) {
    display: flex;
  }
`

const NavLink = styled.div`
  font-size: 16px;
  color: #fff !important;
  margin-left: 35px;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;

  a {
    color: #fff;
  }

  &:hover {
    color: orange;
    text-decoration: underline;
  }
`

const NavLinksMobile = styled.div`
  display: block;
  width: 100%;
  background: #292929;
  position: absolute;
  top: 60px;
  left: 0;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
  max-width: 1440px;
  padding: 0 35px;
  display: flex;
  flex-flow: column;
  align-items: flex-start;

  ${({visible}) => visible && `
    max-height: 600px;
  `}

  @media(min-width: 494px) {
    top: 45px;
  }

  @media(min-width: 1280px) {
    display: none;
  }
`

const NavLinkMobile = styled.div`
  font-size: 18px;
  width: 100%;
  margin: 25px 0;
  text-align: left;
  color: #fff;

  a {
    color: #fff;
  }
`

const HamburgerMenu = styled.div`
  position: absolute;
  right: 35px;
  top: 12px;
  width: 25px;
  color: #fff;
  font-size: 25px;
  cursor: pointer;

  @media(min-width: 1280px) {
    display: none;
  }
`

const Navigation = (props) => {
  const [menuVisible, setMobileVisible] = useState(false);
  const isHome = props.location.pathname == '/';

  const toggleMenu = () => {
    setMobileVisible(!menuVisible);
  }

  const navToHome = (el) => {
    if (menuVisible) setMobileVisible(false);
    props.history.push('/');
    setTimeout(() => {
      const elementTop = document.getElementById(el).offsetTop;
      window.scrollTo(0, elementTop - 60);
    }, 100);
  }

  return (
    <StyledNavContainer>
      <StyledNavInner>
        <StyledNavLogoContainer>
          <Link to='/' onClick={() => {
                window.scrollTo(0, 0);
                if (menuVisible) setMobileVisible(false);
              }}><Logo src={logoImg} /></Link> <span>by</span>
              <a href="https://www.globalinheritance.org" target="_blank">
                <GiLogo src={giLogoImg} />
              </a>
        </StyledNavLogoContainer>
        {isHome ? (
          <div>
            <NavLinks>
              <NavLink><Link to='/posted-office' onClick={() => {
                window.scrollTo(0, 0);
                setMobileVisible(false);
              }}>POSTed Office</Link></NavLink>
              {/* <NavLink><Link to='/all-in' onClick={() => window.scrollTo(0, 0)}>All In</Link></NavLink> */}
              <NavLink><Link to='/students-for-justice' onClick={() => {
                window.scrollTo(0, 0);
                setMobileVisible(false);
              }}>Students for Justice</Link></NavLink>
              <AnchorLink href="#about" offset='60'><NavLink>About</NavLink></AnchorLink>
              <AnchorLink href="#artists" offset='60'><NavLink>Artists</NavLink></AnchorLink>
              <AnchorLink href="#gallery" offset='60'><NavLink>Gallery</NavLink></AnchorLink>
              {/* <AnchorLink href="#shop" offset='60'><NavLink>Shop</NavLink></AnchorLink> */}
              <AnchorLink href="#contact" offset='60'><NavLink>Contact</NavLink></AnchorLink>
            </NavLinks>
            <NavLinksMobile visible={menuVisible}> 
              <NavLinkMobile><Link to='/posted-office' onClick={() => {
                window.scrollTo(0, 0);
                setMobileVisible(false);
              }}>POSTed Office</Link></NavLinkMobile>
              {/* <NavLinkMobile><Link to='/all-in' onClick={() => window.scrollTo(0, 0)}>All In</Link></NavLinkMobile> */}
              <NavLinkMobile><Link to='/students-for-justice' onClick={() => {
                window.scrollTo(0, 0);
                setMobileVisible(false);
              }}>Students for Justice</Link></NavLinkMobile>
              <AnchorLink href="#about" offset='60'><NavLinkMobile onClick={() => toggleMenu()}>About</NavLinkMobile></AnchorLink>
              <AnchorLink href="#artists" offset='60'><NavLinkMobile onClick={() => toggleMenu()}>Artists</NavLinkMobile></AnchorLink>
              <AnchorLink href="#gallery" offset='60'><NavLinkMobile onClick={() => toggleMenu()}>Gallery</NavLinkMobile></AnchorLink>
              {/* <AnchorLink href="#shop" offset='60'><NavLinkMobile onClick={() => toggleMenu()}>Shop</NavLinkMobile></AnchorLink> */}
              <AnchorLink href="#contact" offset='60'><NavLinkMobile onClick={() => toggleMenu()}>Contact</NavLinkMobile></AnchorLink>
            </NavLinksMobile>
          </div>
        ) : (
          <div>
            <NavLinks>
              <NavLink><Link to='/posted-office' onClick={() => {
                window.scrollTo(0, 0);
                setMobileVisible(false);
              }}>POSTed Office</Link></NavLink>
              {/* <NavLink><Link to='/all-in' onClick={() => window.scrollTo(0, 0)}>All In</Link></NavLink> */}
              <NavLink><Link to='/students-for-justice' onClick={() => {
                window.scrollTo(0, 0);
                setMobileVisible(false);
              }}>Students for Justice</Link></NavLink>
              <NavLink onClick={() => navToHome('about')}>About</NavLink>
              <NavLink onClick={() => navToHome('artists')}>Artists</NavLink>
              <NavLink onClick={() => navToHome('gallery')}>Gallery</NavLink>
              {/* <NavLink onClick={() => navToHome('shop')}>Shop</NavLink> */}
              <NavLink onClick={() => navToHome('contact')}>Contact</NavLink>
            </NavLinks>
            <NavLinksMobile visible={menuVisible}> 
              <NavLinkMobile><Link to='/posted-office' onClick={() => {
                window.scrollTo(0, 0);
                setMobileVisible(false);
              }}>POSTed Office</Link></NavLinkMobile>
              {/* <NavLinkMobile><Link to='/all-in' onClick={() => window.scrollTo(0, 0)}>All In</Link></NavLinkMobile> */}
              <NavLinkMobile><Link to='/students-for-justice' onClick={() => {
                window.scrollTo(0, 0);
                setMobileVisible(false);
              }}>Students for Justice</Link></NavLinkMobile>
              <NavLinkMobile onClick={() => navToHome('about')}>About</NavLinkMobile>
              <NavLinkMobile onClick={() => navToHome('artists')}>Artists</NavLinkMobile>
              <NavLinkMobile onClick={() => navToHome('gallery')}>Gallery</NavLinkMobile>
              {/* <NavLinkMobile onClick={() => navToHome('shop')}>Shop</NavLinkMobile> */}
              <NavLinkMobile onClick={() => navToHome('contact')}>Contact</NavLinkMobile>
            </NavLinksMobile>
          </div>
        )}
        
        <HamburgerMenu onClick={() => toggleMenu()}><i className="fa fa-bars"></i></HamburgerMenu>
      </StyledNavInner>
    </StyledNavContainer>
  )
}

export default withRouter(Navigation)