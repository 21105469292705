import React, { useState } from 'react'
import styled from 'styled-components'
import SectionHeader from '../../components/SectionHeader'
import ArtistModal from '../../components/ArtistModal'


const StyledArtistsContainer = styled.section`
  width: 100%;
  max-width: 992px;
  padding: 0 35px;
  margin: 100px auto;
`

const StyledListContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  width: 100%;
`

const StyledList = styled.ul`
  position: relative;
  width: 50%;
`

const StyledListItem = styled.li`
  list-style-type: none;
  text-align: left;
  color: #fff;
  font-size: 18px;
  line-height: 1.2;
  padding: 5px 0;
  font-weight: bold;
  // cursor: pointer;

  // &:hover {
  //   color: orange;
  //   text-decoration: underline;
  // }

`

const artistImages = {
  david: 'https://via.placeholder.com/400x300',
  danny: 'https://via.placeholder.com/400x300',
  joe: 'https://via.placeholder.com/400x300',
  kelsey: 'https://via.placeholder.com/400x300',
  bwana: 'https://via.placeholder.com/400x300',
  dan: 'https://via.placeholder.com/400x300',
  sasha: 'https://via.placeholder.com/400x300',
  sarah: 'https://via.placeholder.com/400x300',
  luke: 'https://via.placeholder.com/400x300',
}

const Artists = () => {
  const [showArtistModal, setShowArtistModal] = useState(false)
  const [artistName, setArtistName] = useState('')
  const [artistBio, setArtistBio] = useState('')
  const [artistImage, setArtistImage] = useState('')
  
  return (
    <StyledArtistsContainer id="artists">
      <SectionHeader section="Artists" titleText="Bringing your ideas to life" />
      <StyledListContainer>
        <StyledList>
          <StyledListItem 
            // onClick={() => {
            //   setArtistName('David "MEGGS" Hook')
            //   setArtistBio('Lorem ipsum')
            //   setArtistImage(artistImages.david)
            //   setShowArtistModal(true)
            // }}
          >
            David "MEGGS" Hooke
          </StyledListItem>
          <StyledListItem 
            // onClick={() => {
            //   setArtistName('Danny Schlitz')
            //   setArtistBio('Lorem ipsum')
            //   setArtistImage(artistImages.danny)
            //   setShowArtistModal(true)
            // }}
          >
            Danny Schlitz
          </StyledListItem>
          <StyledListItem 
            // onClick={() => {
            //   setArtistName('Joe LedbeAer')
            //   setArtistBio('Lorem ipsum')
            //   setArtistImage(artistImages.joe)
            //   setShowArtistModal(true)
            // }}
          >
            Joe LedbeAer
          </StyledListItem>
          <StyledListItem 
            // onClick={() => {
            //   setArtistName('Kelsey Gallo')
            //   setArtistBio('Lorem ipsum')
            //   setArtistImage(artistImages.kelsey)
            //   setShowArtistModal(true)
            // }}
          >
            Kelsey Gallo
          </StyledListItem>
          <StyledListItem 
            // onClick={() => {
            //   setArtistName('Bwana Spoons')
            //   setArtistBio('Lorem ipsum')
            //   setArtistImage(artistImages.bwana)
            //   setShowArtistModal(true)
            // }}
          >
            Bwana Spoons
          </StyledListItem>
        </StyledList>
        <StyledList>
          <StyledListItem 
              // onClick={() => {
              //   setArtistName('Dan Smith')
              //   setArtistBio('Lorem ipsum')
              //   setArtistImage(artistImages.dan)
              //   setShowArtistModal(true)
              // }}
            >
              Dan Smith
          </StyledListItem>
          <StyledListItem 
              // onClick={() => {
              //   setArtistName('Sasha Vinogradova')
              //   setArtistBio('Lorem ipsum')
              //   setArtistImage(artistImages.sasha)
              //   setShowArtistModal(true)
              // }}
            >
              Sasha Vinogradova
          </StyledListItem>
          <StyledListItem 
              // onClick={() => {
              //   setArtistName('Sarah Lyons')
              //   setArtistBio('Lorem ipsum')
              //   setArtistImage(artistImages.sarah)
              //   setShowArtistModal(true)
              // }}
            >
              Sarah Lyons
          </StyledListItem>
          <StyledListItem 
              // onClick={() => {
              //   setArtistName('Luke McGarry')
              //   setArtistBio('Lorem ipsum')
              //   setArtistImage(artistImages.luke)
              //   setShowArtistModal(true)
              // }}
            >
              Luke McGarry
          </StyledListItem>
          <StyledListItem>+ Many More</StyledListItem>
        </StyledList>
      </StyledListContainer>

      {showArtistModal && (
        <ArtistModal 
          toggle={() => setShowArtistModal(!showArtistModal)}
          artistName={artistName}
          artistBio={artistBio}
          artistImage={artistImage}
        />
      )}
  </StyledArtistsContainer>
  )
}

export default Artists