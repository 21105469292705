import React from 'react'
import styled from 'styled-components'

const StyledBody = styled.div`
  color: #fff;
  text-align: left;
  padding: 35px;
  max-width: 750px;
  margin: 0 auto;

  h1, h2 {
    margin: 5px 0;
    font-weight: bold;
  }

  h1 {
    text-align: center;
  }

  p {
    margin-bottom: 15px;
  }
`;  

const Rules = () => (
  <StyledBody>
    <h1>GLOBAL INHERITANCE<br />CONTEST RULES</h1>

    <h2>ELIGIBILITY</h2>

    <p>This contest is open only to legal residents of the fifty (50) United States (including District of Columbia) who are at least eighteen (18) years old at the time of entry, unless otherwise noted in the footer of each individual promotion. The immediate family (spouse, parents, siblings and children) and household members of Global Inheritance are not eligible. Subject to all applicable federal, state and local laws and regulations. Void where prohibited. Participation constitutes entrant’s full and unconditional agreement to these Official Rules and Sponsor’s and Global Inheritance’s decisions, which are final and binding in all matters related to the Contest. Winning a prize is contingent upon fulfilling all requirements set forth herein and judges selection your poster concept.</p>

    <h2>TIMING</h2>
    <p>The contest begins on or around at 12 p.m. EST on April 22, 2021 and ends at 8 pm. EST on April 30, 2021. (the “Promotion Period”). All entries must be received no later than 8:00 pm EST on April 30, 2021. No limit of entries per person. Entries submitted or attempted to be submitted outside the Promotion Period will be automatically disqualified. 
    </p>

    <h2>HOW TO ENTER</h2>
    <p>Submit your Concept via the form at www.postedstudio.org/designcontest</p>

    <h2>WINNER SELECTION</h2>
    <p>Global Inheritance is 501(c)(3) organization whose decisions as to the administration and operation of the Promotion and the selection of potential winners are final and binding in all matters related to the Promotion. A committee of Global Inheritance judges will select the winners,, subject to its own approval process, from all eligible entries on or around the end date specified for the Promotion Period. Global Inheritance expressly reserves the right to determine the final winners of this contest. </p>

    <h2>VERIFICATION OF POTENTIAL WINNERS</h2>
    <p>Potential winners must continue to comply with all terms and conditions of these Official Rules, and winning is contingent upon fulfilling all requirements. The potential winner will be notified by email. Except where prohibited, each potential prize winner will be required to reply to notification email, within three (3) days of the date notice is sent (as noted on email timestamp),  in order to claim his/her prize.</p>
    <p>If a potential winner of any prize cannot be contacted, fails to reply to the notification email within the required time period (if applicable), or prize is returned as undeliverable, potential winner forfeits prize.</p>
    <p>
    In the event that a potential Prize winner is disqualified for any reason, Global Inheritance will award the applicable prize to an alternate winner by random drawing from among all remaining eligible entries. There will be one (1) alternate drawing after which the applicable prize will remain unawarded.</p>

    <h2>ALL PRIZES</h2>
    <p>All prizes are non-transferable and no substitution will be made except as provided herein at Global Inheritance’s sole discretion. Global Inheritance reserves the right to substitute a prize for one of equal or greater value if the designated prize should become unavailable for any reason. Winner is responsible for all taxes and fees associated with prize receipt and/or use.</p>

    <h2>GENERAL CONDITIONS</h2>
    <p>
    By submitting an entry or accepting a prize, Entrant represent and warrant that all entries submitted by the Entrant are the original creation of the Entrant, have not been copied in whole or in part from any other work, do not violate or infringe any copyright, trademark or proprietary right of any third party, and are the sole and exclusive property of the Entrant. Entrants assume all risk of lost, late, misdirected, incomplete or illegible submissions. All entries become the sole property of Global Inheritance, and will not be acknowledged or returned. By submitting an entry, Entrants grant Global Inheritance, its affiliates, and agents permission to use entries for any purpose and in any media whatsoever, without further compensation to Entrants. Any person featured in any entries submitted must have provided his or her consent to Entrant for the submission of his or her image in this Contest. By entering this Contest, Entrant represents that such consent has been received. Failure to obtain such consent will deem the entry ineligible for participation in the Contest. Entrants agree to indemnify and hold harmless Global Inheritance and its affiliates and agents for any action brought by a person whose consent was not obtained by the Entrant. In the event of a dispute about entries submitted by multiple individuals, the entry will be deemed to have been submitted by Entrant who first submitted the entry.. 
    </p>
    <p>Global Inheritance reserves the right to cancel, suspend and/or modify the Promotion, or any part of it, if any fraud, technical failures or any other factor beyond Global Inheritance’s reasonable control impairs the integrity or proper functioning of the Sweepstakes, as determined by Global Inheritance in its sole discretion. In such an event, Global Inheritance reserves the right to award the prizes at random from among the eligible entries received up to the time of the impairment. Global Inheritance reserves the right, in its sole discretion, to disqualify any individual it finds to be tampering with the entry process or the operation of the Contest or to be acting in violation of these Official Rules or any other promotion in a disruptive manner. Any attempt by any person to deliberately undermine the legitimate operation of the Contest may be a violation of criminal and civil law, and, should such an attempt be made, Global Inheritance reserves the right to seek damages from any such person to the fullest extent permitted by law. Global Inheritance’s failure to enforce any term of these Official Rules shall not constitute a waiver of that provision.</p>
    <p>
    Use of automated entry devices or programs is prohibited. Any attempts by an individual to influence the contest via a bot script or other brute-force attack will result in that person and any entry originating from that IP address becoming ineligible. Global Inheritance reserves the right to cancel online entries for the competition without notice if it becomes infected with a virus or is otherwise technically corrupted or unfeasible. Global Inheritance is not responsible for lost, late, misdirected or illegible entries or for any computer, online, telephone or technical malfunctions or failure or delayed, garbled or corrupted data transmitted by participants via the Internet. All entries become the property of Global Inheritance. By submitting an entry to Global Inheritance, entrants accept and agree to be bound by these Official Rules and the decisions of the judges, which shall be final, binding and conclusive on all matters. 
    </p>
    <p>
    Entries must not be unlawful, libelous, slanderous, defamatory, invasive or deemed harmful, vulgar, obscene, derogatory, pornographic, abusive, harassing, threatening, hateful, objectionable with respect to race, religion, creed, national origin or gender, or otherwise unfit for publication in the sole opinion of Global Inheritance. Any image that in the sole discretion of Global Inheritance is considered inappropriate or unfit for publication will be automatically disqualified.
    </p>

    <h2>RELEASE</h2>
    <p>By receipt of any prize, winner agrees to release and hold harmless Global Inheritance, its sponsors, and their respective subsidiaries, affiliates, suppliers, distributors, advertising/promotion agencies and each of their respective parent companies and each such company’s officers, directors, employees and agents (collectively, the “Released Parties”) from and against any claim or cause of action, including, but not limited to, personal injury, death, or damage to or loss of property, arising out of participation in the Promotion or receipt or use or misuse of any prize.</p>

    <h2>LIMITATIONS OF LIABILITY</h2>
    <p>The Released Parties are not responsible for: (1) any incorrect or inaccurate information, whether caused by entrants, printing errors or by any of the equipment or programming associated with or utilized in the Promotion; (2) technical failures of any kind, including, but not limited to malfunctions, interruptions, or disconnections in phone lines or network hardware or software; (3) unauthorized human intervention in any part of the entry process or the Promotion; (4) technical or human error which may occur in the administration of the Promotion or the processing of entries; or (5) any injury or damage to persons or property which may be caused, directly or indirectly, in whole or in part, from entrant’s participation in the Promotion or receipt or use or misuse of any prize. If for any reason an entrant’s entry is confirmed to have been erroneously deleted, lost, or otherwise destroyed or corrupted, entrant’s sole remedy is another entry in the Sweepstakes, provided that if it is not possible to award another entry due to discontinuance of the Promotion, or any part of it, for any reason, Global Inheritance, at its discretion, may elect to hold a random drawing from among all eligible entries received up to the date of discontinuance for any or all of the prizes offered herein. No more than the stated number of prizes will be awarded. In the event that production, technical, seeding, programming or any other reasons cause more than stated number of prizes as set forth in these Official Rules to be available and/or claimed, Administrator reserves the right to award only the stated number of prizes by a random drawing among all legitimate, unawarded, eligible prize claims.</p>

    <h2>WINNERS LIST</h2>
    <p>To receive a list of contest winner names, and city of residence, please send a self addressed stamped envelope to:</p>
    <p>
    Global Inheritance 
    <br />
    1855 Industrial Street
    <br />
    Suite 613
    <br />
    Los Angeles, CA 90021
    </p>

    <h2>CONTEST SPONSOR</h2>
    <p>This contest is sponsored by: Global Inheritance, Goldenvoice, JuneShine, Propeller and Monty’s Good Burger. </p>
    <p>Any questions regarding this Contest should be directed to Eric Ritz at <a href="mailto:ericritz@globalinheritance.org">ericritz@globalinheritance.org</a></p>
  </StyledBody>
);

export default Rules;