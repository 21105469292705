import React, { useState } from 'react'
import PropTypes from 'prop-types'
import jsonp from 'jsonp'
import styled from 'styled-components'

const StyledForm = styled.form`
  width: 100%;
  background: transparent;
  padding: 25px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
`
const StyledFormTitle = styled.h1`
  font-size: 32px;
  font-family: 'Glegoo', serif;
  font-weight: 700;
  font-style: normal;
  margin: 10px 0;
  color: #fff;
`

const StyledInputContainer = styled.div`
  width: 100%;

  ${({ width }) => width && `
    @media(min-width: 768px) {
      width: ${width};
    }
  `}

  input[type="text"],
  input[type="email"] {
    background: transparent;
    background-image: none;
    box-shadow: none;
    border: 2px solid #fff;
    width: 100%;
    padding: 15px;
    margin-bottom: 20px;
    color: #fff;

    &::placeholder {
      color: #fff;
    }
  }

  input[type="checkbox"] {
    position: absolute; // take it out of document flow
    opacity: 0; // hide it

    & + label {
      position: relative;
      cursor: pointer;
      padding: 0;
      color: #fff;
    }

    // Box.
    & + label:before {
      content: '';
      margin-right: 10px;
      display: inline-block;
      vertical-align: text-top;
      width: 20px;
      height: 20px;
      background: #fff;
    }

    // Box hover
    &:hover + label:before {
      background: #f35429;
    }
    
    // Box focus
    &:focus + label:before {
      box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
    }

    // Box checked
    &:checked + label:before {
      background: #f35429;
    }
    
    // Disabled state label.
    &:disabled + label {
      color: #b8b8b8;
      cursor: auto;
    }

    // Disabled box.
    &:disabled + label:before {
      box-shadow: none;
      background: #ddd;
    }

    // Checkmark. Could be replaced with an image
    &:checked + label:after {
      content: '';
      position: absolute;
      left: 5px;
      top: 9px;
      background: white;
      width: 2px;
      height: 2px;
      box-shadow: 
        2px 0 0 white,
        4px 0 0 white,
        4px -2px 0 white,
        4px -4px 0 white,
        4px -6px 0 white,
        4px -8px 0 white;
      transform: rotate(45deg);
    }
  }
`

const StyledButton = styled.button`
  border: 2px solid #fff;
  padding: 10px 15px;
  box-shadow: none;  
  color: #000;
  margin: 25px auto;
  font-weight: bold;
  font-size: 18px;
  background: #fff;
  transition: all 0.3s ease;
  text-transform: uppercase;

  &:hover {
    background: transparent;
    color: #fff;
  }
`

const StyledMessageAlert = styled.div`
  width: 100%;

  p {
    font-size: 18px;
  }
`

const Mailchimp = (props) => {
  const [status, setStatus] = useState('');
  const [inputs, setInputs] = useState({});
  const { fields, styles } = props;
  const messages = {
    ...Mailchimp.defaultProps.messages,
    ...props.messages
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    const { fields, action } = props;
    const values = fields.map(field => {
      if (field.type === 'checkbox') {
        return inputs[field.name] ? `${field.name}=1` : '';
      }
      return `${field.name}=${encodeURIComponent(inputs[field.name])}`;
    }).join('&');
    const path = `${action}&${values}`;
    const url = path.replace('/post?', '/post-json?');
    const regex = /^([\w_\.\-\+])+\@([\w\-]+\.)+([\w]{2,10})+$/;
    const email = inputs['EMAIL'];
    (!regex.test(email)) ? setStatus('empty') : sendData(url);
  };

  const sendData = (url) => {
    setStatus('sending');
    jsonp(url, { param: 'c' }, (err, data) => {
      if (data.msg.includes('already subscribed')) {
        setStatus('duplicate')
      } else if (err) {
        setStatus('error')
      } else if (data.result !== 'success') {
        setStatus('error')
      } else {
        setStatus('success')
      };
    });
  }

  return (
    <div>
      <StyledFormTitle id={props.tag}>{props.title || 'Subscribe'}</StyledFormTitle>
      <StyledForm onSubmit={(e) => handleSubmit(e)}>
        {fields.map(input =>
          <StyledInputContainer key={input.name} width={input.width}>
            <input
              {...input}
              width={input.width}
              defaultChecked={inputs[input.name]}
              onChange={({ target }) => {
                if (input.type === 'checkbox') {
                  setInputs({
                    ...inputs,
                    [input.name]: !inputs[input.name]
                  })
                } else {
                  setInputs({ ...inputs, [input.name]: target.value })
                }
              }}
              defaultValue={inputs[input.name]}
              id={input.name}
            />
            {input.label && <label htmlFor={input.name}>{input.label}</label>}
          </StyledInputContainer>
        )}
        <StyledButton
          disabled={status === 'sending' || status === 'success'}
          type='submit'
        >
          {messages.button}
        </StyledButton>
        <StyledMessageAlert>
          {status === 'sending' && <p style={styles.sendingMsg}>{messages.sending}</p>}
          {status === 'success' && <p style={styles.successMsg}>{messages.success}</p>}
          {status === 'duplicate' && <p style={styles.duplicateMsg}>{messages.duplicate}</p>}
          {status === 'empty' && <p style={styles.errorMsg}>{messages.empty}</p>}
          {status === 'error' && <p style={styles.errorMsg}>{messages.error}</p>}
        </StyledMessageAlert>
      </StyledForm>
    </div>
  )
}

Mailchimp.defaultProps = {
  messages: {
    sending: 'Sending...',
    success: 'Thank you for signing up!',
    error: 'An unexpected internal error has occurred.',
    empty: 'You must write an e-mail.',
    duplicate: 'Too many subscribe attempts for this email address',
    button: 'Sign Up'
  },
  styles: {
    sendingMsg: {
      color: '#0652DD'
    },
    successMsg: {
      color: '#009432'
    },
    duplicateMsg: {
      color: '#EE5A24'
    },
    errorMsg: {
      color: '#ED4C67'
    }
  }
};

Mailchimp.propTypes = {
  action: PropTypes.string,
  messages: PropTypes.object,
  fields: PropTypes.array,
  styles: PropTypes.object,
  tag: PropTypes.string,
};

export default Mailchimp