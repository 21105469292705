import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap'

const StyledDetails = styled.div`
  position: relative;
  width: 100%;

  h1 {
    margin-bottom: 25px;
    font-size: 24px;
  }

  p {
    margin-bottom: 25px;

    &:nth-child(2) {
      font-family: celestia-antiqua-mvb, serif;
      font-weight: bold;
      font-style: normal;
      font-size: 28px;
    }
  }
`

const ShopDetails = ({
  shopData, close
}) => {
  let customFields = {}
  if (shopData.customFields && shopData.customFields.length) {
    shopData.customFields.forEach((field, i) => {
      customFields[`data-item-custom${i + 1}-name`] = field.name;
      customFields[`data-item-custom${i + 1}-options`] = field.options;
    });
  }

  console.log(customFields);

  return (
    <StyledDetails>
      <h1>{shopData.title}</h1>
      <p>${shopData.price}</p>
      <p>{shopData.desc}</p>
      <Button 
        className="snipcart-add-item"
        data-item-id={shopData.id}
        data-item-name={shopData.title}
        data-item-price={shopData.price}
        data-item-weight="100"
        data-item-url="/shopdata.json"
        data-item-description={shopData.desc}
        data-item-image={shopData.image}
        color="primary" 
        onClick={() => {
          close()
        }}
        {...customFields}
      >
        Add to Cart
      </Button>
    </StyledDetails>
  );
};

export default ShopDetails;
