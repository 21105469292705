import React, { useState, useEffect } from 'react' 
import styled, { createGlobalStyle } from 'styled-components'
import get from 'lodash/get'
import logo from '../../spa-logo.png'
import spaBg from '../../spa-logo-bg.jpg'
import GALLERY_DATA from './examples.json'
import ExpandButton from '../../expand.svg'
import DownloadButton from '../../download.svg'
import GalleryModal from '../../components/GalleryModal'

const GlobalStyles = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Glegoo:wght@400;700&display=swap');

  body {
    background-color: #fff;
    color: #000;
  }
`;

const StyledContainer = styled.section`
  width: 100%;
  max-width: 992px;
  padding: 0 35px;
  margin: 25px auto;
`

const StyledHeaderBackground = styled.div`
  width: 100vw;
  height: 300px;
  background-image: url(${spaBg});
  background-size: cover;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  
  img {
    width: 80%;
    max-width: 600px;
    margin: 0 auto;
  }
`;

const StyledInner = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
`
const StyledTextTitle = styled.h1`
  font-size: 32px;
  font-family: 'Glegoo', serif;
  font-weight: 500;
  font-style: normal;
  margin: 25px 0;
  text-align: center;
  width: 100%;
  position: relative;

  ${({ left }) => left && `
    text-align: left;
  `}

  &::after {
    content: "";
    width: 50px;
    height: 3px;
    background: #ccc;
    left: 50%;
    bottom: -10px;
    transform: translateX(-50%);
    position: absolute;

    ${({ left }) => left && `
      left: 0;
      transform: none;
    `}
  }
`

const StyledText = styled.div`
  font-size: 18px;
  margin-bottom: 25px;
  line-height: 1.4;
  text-align: left;
  width: 100%;

  img {
    float: right;
    width: 100%;
    max-width: 350px;
    padding: 15px;
  }

  hr {
    background: #fff;
    width: 200px;
  }

  ${({ link }) => link && `
    text-decoration: underline;
    cursor: pointer;
  `}

  ${({ small }) => small && `
    font-size: 14px;
  `}

  ${({ large }) => large && `
    font-size: 22px
  `}  

  ${({ center }) => center && `
    text-align: center;
  `}

  ${({ bottom }) => bottom && `
    position: absolute;
    bottom: -10px;
  `}

  ${({half}) => half && `
    @media(min-width: 768px) {
      width: calc(50% - 25px);
    }
  `}

  ul {
    list-style-type: none;

    li {
      margin: 0 0 15px 45px;
    }
  }

  .disc {
    list-style-type: disc;
  }
`

const StyledListContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  width: 100%;
`

const StyledListItem = styled.li`
  width: calc(100% - 20px);
  margin-bottom: 120px;
  position: relative;
  list-style-type: none;
  border: 1px solid #000;

  img {
    width: 100%;
    padding-bottom: 50px;
  }

  @media(min-width: 768px) {
    width: calc(45% - 20px);
  }
`

const StyledForm = styled.form`
  width: 100%;
  max-width: 600px;
  padding: 25px;
  display: flex;
  flex-flow: column;
  margin: 0 auto;
  height: 100vh;
  justify-content: flex-start;

  label {
    font-size: 18px;
    color: #000;
  }

  input {
    height: 38px;
    border-radius: 8px;
    font-size: 18px;
  }

  button {
    width: 200px;
    background: #666;
    border: #fff
    padding: 15px;
    margin: 15px auto;
    color: #fff;
  }
`;

const SPARequest = () => {
  const [isAuth, setIsAuth] = useState(false);
  const [error, setError] = useState(null);
  const [activeImageUrl, setActiveImageUrl] = useState('');
  const [activeDesc, setActiveDesc] = useState(null);
  const [password, setPassword] = useState("");
  const [showGalleryModal, setShowGalleryModal] = useState(false);
  const galleryData = get(GALLERY_DATA, 'data', [])
  const activeGalleryPosters = galleryData.find(x => x.name === 'Examples').posters;

  useEffect(() => {

    if (typeof window !== "undefined") {
      window.scrollTo(0, 0);
      const auth = localStorage.getItem("authorization");
      const now = new Date();
      if (auth) {
        const item = JSON.parse(auth);
        if (now.getTime() > item.expiry) {
          // If the item is expired, delete the item from storage
          // and return null
          localStorage.removeItem("authorization");
          setIsAuth(false);
          return null
        } else {
          setIsAuth(true);
        }
      };
    }
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    if (typeof window !== "undefined") {
      const now = new Date();
      if (password === "PostedSPA") {
        const item = {
          value: true,
          expiry: now.getTime() + 86400000
        };
        setIsAuth(true);
        setError(false);
        localStorage.setItem("authorization", JSON.stringify(item));
      } else {
        setError(true);
      }
    }
  };

  return (
    <>
      <StyledHeaderBackground>
        <img src={logo} alt="Acting on Behalf of Planet Earth" />
      </StyledHeaderBackground>
      <StyledContainer id="poster-submission">
      <GlobalStyles />
      <StyledInner>
        {!isAuth ? (
          <StyledForm onSubmit={(e) => onSubmit(e)}>
            <StyledTextTitle>Enter Password</StyledTextTitle>
            <input name="password" type="password" value={password} onChange={(val) => setPassword(val.currentTarget.value)} />
            <button type="submit">Submit</button>
            {error && (
              <StyledText center>
                Sorry, you've entered the incorrect password.
              </StyledText>
            )}
            <StyledText center>To request access, please email <a href="mailto:info@globalinheritance.org">info@globalinheritance.org</a></StyledText>
          </StyledForm>
         
        ) : (
          <>
            <StyledText center>Request full resolution artwork by filling out the <a href="#request-form">form below</a> with your desired selection.</StyledText>
            <StyledListContainer>
              {activeGalleryPosters.map(gallery => (
                <>
                  <StyledListItem key={gallery.thumbUrl}>
                    <img src={gallery.thumbUrl} />
                    {/* <StyledRolloverContainer>
                      <StyledRolloverButton onClick={() => {
                        setActiveImageUrl(gallery.thumbUrl)
                        setActiveDesc(null)
                        setShowGalleryModal(true)
                      }}>
                        <img src={ExpandButton} />
                      </StyledRolloverButton>
                      <StyledRolloverButton>
                        <a href="#request-form">
                          <img src={DownloadButton} />
                        </a>
                      </StyledRolloverButton>
                    </StyledRolloverContainer> */}
                    <StyledText link large center bottom onClick={() => {
                      setActiveImageUrl(gallery.bioPic)
                      setActiveDesc(gallery.desc)
                      setShowGalleryModal(true)
                    }}>by {gallery.poster}</StyledText>
                  </StyledListItem>
                </>
              ))}
            </StyledListContainer>
            {showGalleryModal && (
              <GalleryModal 
                toggle={() => setShowGalleryModal(!showGalleryModal)}
                imgUrl={activeImageUrl}
                desc={activeDesc}
              />
            )}
            <StyledContainer>
              <div id="request-form">
                <iframe class="airtable-embed" src="https://airtable.com/embed/shrZ16kPbpbIosVc7?backgroundColor=red" frameborder="0" onmousewheel="" width="100%" height="533" style={{'background': '#fff', 'box-shadow': '4px 4px 0 #000', 'border': '1px solid #000'}}></iframe>
              </div>
            </StyledContainer>
          </>
        )}
      </StyledInner>

    </StyledContainer>
    </>
  )
}

export default SPARequest