import React, { Fragment } from 'react'
import styled from 'styled-components'

const StyledSectionContainer = styled.div`
  text-align: left;
  color: #fff;
  width: 100%;

  @media(min-width: 768px) {
    width: 50%;
  }
`

const StyledH4 = styled.h4`
  font-size: 24px;
  margin-bottom: 25px;
` 

const StyledH1 = styled.h1`
  font-size: 50px;
  font-family: celestia-antiqua-mvb, serif;
  font-weight: 400;
  font-style: normal;
  margin-bottom: 25px;
  max-width: 390px;
  line-height: 1;
`

const SectionHeader = ({ section, titleText }) => (
  <StyledSectionContainer>
    <StyledH4>{section}</StyledH4>
    <StyledH1>{titleText}</StyledH1>
  </StyledSectionContainer>
)

export default SectionHeader
