import React, { useState } from 'react'
import styled from 'styled-components'
import get from 'lodash/get'
import SectionHeader from '../../components/SectionHeader'
import GalleryModal from '../../components/GalleryModal'
import ShopModal from '../../components/ShopModal'
import GALLERY_DATA from './gallery.json'
import ExpandButton from '../../expand.svg';
import DownloadButton from '../../download.svg';
import BuyButton from '../../cart.svg';


const StyledGalleryContainer = styled.section`
  width: 100%;
  max-width: 992px;
  padding: 0 35px;
  margin: 100px auto;
`

const StyledNavContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 25px;

  @media(min-width: 768px) {
    max-width: 80%;
  }
`

const StyledNav = styled.a`
  font-size: 16px;
  color: #fff !important;
  margin: 10px 25px 10px 0;
  font-weight: bold;
  cursor: pointer;

  ${({isActive}) => isActive &&`
    color: orange !important;
  `}

  &:hover {
    color: orange !important;
  }
`

const StyledListContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  width: 100%;
`

const StyledListItem = styled.li`
  width: calc(100% - 20px);
  margin-bottom: 120px;
  position: relative;

  img {
    width: 100%;
  }

  @media(min-width: 768px) {
    width: calc(50% - 20px);
  }
`

const StyledRolloverContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 80px;
  background: rgba(0,0,0,0.5);
  top: 100%;
  left: 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease;

  @media(min-width: 769px) {
    height: 95.45%;
    top: 16px
    opacity: 0;
  }

  &:hover {
    opacity: 1;
  }
`

const StyledRolloverButton = styled.div`
  width: 35px;
  height: 35px;
  margin: 20px;
  cursor: pointer;

  @media(min-width: 769px) {
    width: 50px;
    height: 50px;
  }

  img {
    width: 100%;
  }
`

const Gallery = () => {
  const [activeGallery, setActiveGallery] = useState('Coachella 2024 - Weekend 1')
  const [showGalleryModal, setShowGalleryModal] = useState(false)
  const [showGalleryShopModal, setShowGalleryShopModal] = useState(false)
  const [activeImageUrl, setActiveImageUrl] = useState('')
  const [activeShopData, setActiveShopData] = useState({})

  const galleryData = get(GALLERY_DATA, 'data', [])
  const activeGalleryPosters = galleryData.find(x => x.name === activeGallery).posters

  const handleDownloadClick = (gallery, shop) => {
    if (shop) {
      setActiveShopData(gallery.shopData)
      setShowGalleryShopModal(true)
    } else {
      window.open(gallery.posterUrl)
    }
  }

  return (
    <StyledGalleryContainer id="gallery">
      <SectionHeader section="Gallery" titleText="Created by you at POSTed Studio" />
      <StyledNavContainer>
        {galleryData.map(gallery => (
          <StyledNav key={`${gallery.name}__`} isActive={gallery.name === activeGallery} onClick={() => setActiveGallery(gallery.name)}>{gallery.name}</StyledNav>
        ))}
      </StyledNavContainer>
      <StyledListContainer>
        {activeGalleryPosters.map(gallery => (
          <StyledListItem key={gallery.thumbUrl}>
            <img src={gallery.thumbUrl} />
            <StyledRolloverContainer>
              <StyledRolloverButton onClick={() => {
                setActiveImageUrl(gallery.thumbUrl)
                setShowGalleryModal(true)
              }}>
                <img src={ExpandButton} />
              </StyledRolloverButton>
              {gallery.shop && (
                <StyledRolloverButton onClick={() => handleDownloadClick(gallery, true)}>
                  <img src={BuyButton} />
                </StyledRolloverButton>
              )}
              {gallery.posterUrl ? (
                <StyledRolloverButton onClick={() => handleDownloadClick(gallery)}>
                  <img src={DownloadButton} />
                </StyledRolloverButton>
              ) : null}
            </StyledRolloverContainer>
          </StyledListItem>
        ))}
      </StyledListContainer>

      {showGalleryModal && (
        <GalleryModal 
          toggle={() => setShowGalleryModal(!showGalleryModal)}
          imgUrl={activeImageUrl}
        />
      )}

    {showGalleryShopModal && (
        <ShopModal 
          toggle={() => setShowGalleryShopModal(!showGalleryShopModal)}
          shopData={activeShopData}
          close={() => setShowGalleryShopModal(false)}
        />
      )}
  </StyledGalleryContainer>
  )
}

export default Gallery